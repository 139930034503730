import {
  InfoLabel,
  InfoValue
} from './common';


export default function Blocked({ state, onReset }) {
  return (
    <>
      <div className="text-center">
        <div className="text-2xl font-bold">Check In Blocked</div>
      </div>

      {state?.blocked?.reason && (
        <div>
          <InfoLabel>Reason</InfoLabel>
          <InfoValue>{state.blocked.reason}</InfoValue>
        </div>
      )}

      <button
        className="btn btn-contained-primary"
        onClick={onReset}
      >
        Done
      </button>
    </>
  );
}