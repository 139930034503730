import useHeader from '../hooks/useHeader';

export default function FullPageIFrame({ title, src, iframeProps, goBackURL }) {
  useHeader(src, {
    title,
    action: {
      isBack: true,
      url: goBackURL
    }
  });

  return (
    <div className="flex flex-1 flex-col overflow-auto">
      <div className="flex flex-1 flex-col overflow-auto">
        <iframe
          title={title}
          src={src}
          width="100%"
          height="100%"
          frameBorder={0}
          allowFullScreen={true}
          style={{ height: '100%', flex: 1 }}
          {...iframeProps}
        >
          {title}
        </iframe>
      </div>
    </div>
  );
}