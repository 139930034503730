import * as SocketIOActions from '../actions/socketio';


const initialState = {
  open: false,
  connecting: false,
  disconnecting: false,
  error: null
};


export default function websocket(state = initialState, action) {
  switch (action.type) {
    case SocketIOActions.OPEN:
      return {
        ...initialState,
        open: true
      };
    case SocketIOActions.CONNECTING:
      return {
        ...initialState,
        error: state.error,
        connecting: true
      };
    case SocketIOActions.DISCONNECTING:
      return {
        ...state,
        connecting: false,
        disconnecting: true
      };
    case SocketIOActions.CLOSED:
      return {
        ...state,
        open: false,
        connecting: false,
        disconnecting: false
      };
    case SocketIOActions.ERROR:
      return {
        ...state,
        error: action.meta
      };
    default:
      return state;
  }
}