import styled from 'styled-components';
import clsx from 'clsx';


const ListContainer = styled.div`
  border: 2px solid #f1f1f1;
  border-radius: 6px;
  overflow: hidden;
  & > * {
    border-top: 2px solid #f1f1f1;
  }
  & > *:first-child {
    border-top: none;
  }
`;

function BasicList({ title, children, className, ...props }) {
  return (
    <div className={clsx("select-none", className)} {...props}>
      <div className="text-xl font-medium">{title}</div>
      <ListContainer>
        {children}
      </ListContainer>
    </div>
  );
}

function BasicListItem({ title, children, className, ...props }) {
  return (
    <div className={clsx("bg-white py-2 px-3.5", className)} {...props}>
      <div className="text-lg font-medium mb-1">{title}</div>
      <div>{children}</div>
    </div>
  );
}

export { BasicList, BasicListItem };