import React from 'react';
import ReactSelect from 'react-select';
import clsx from 'clsx';

const styles = { control: styles => ({ ...styles, borderWidth: '0px' }), menuPortal: styles => ({ ...styles, zIndex: 1000 }) };

export default React.forwardRef(function Select({ placeholder, options, value, onChange, className, isClearable, ...props }, ref) {
  return (
    <ReactSelect
      placeholder={placeholder}
      options={options}
      value={value}
      onChange={onChange}
      isClearable={isClearable}
      styles={styles}
      // styles={{ menuPortal: styles => ({ ...styles, zIndex: 1000 })}}
      className={clsx("rounded shadow", className)}
      menuPortalTarget={document.body}
      {...props}
      ref={ref}
    />
  );
});