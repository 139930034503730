const initialState = {
  type: null,
  payload: null,
  meta: null,
  error: false
};

export default function action(state = initialState, action) {
  return {
    ...state,
    ...action
  };
};