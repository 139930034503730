import useSWR from 'swr';
import * as API from '../lib/api';
import { useAuth0 } from '@auth0/auth0-react';

export default function useMeSWR() {
  const { isAuthenticated } = useAuth0();

  // console.log(isAuthenticated);

  return useSWR(isAuthenticated ? '/busers/me' : null, () => API.readBuser());
}