import * as AppActions from '../actions/app';

const initialState = {
  loading: false, // true,
  error: null,
  header: null,
  selected_site: null
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case AppActions.SET_SELECTED_SITE:
      return {
        ...state,
        selected_site: action.payload
      };
    case AppActions.SET_ERROR:
      return {
        ...initialState,
        error: action.payload
      };
    case AppActions.STOP_LOADING:
      return {
        ...state,
        loading: false
      };
    case AppActions.REGISTER_HEADER:
      return {
        ...state,
        header: action.payload
      };
    case AppActions.CLEAR_HEADER:
      return {
        ...state,
        header: state.header?.id === action.payload ? null : state.header
      };
    default:
      return state;
  }
}
