import styled from 'styled-components';

export const BORDER_RADIUS = '6px';

const Base = styled.div`
  display: flex;
  user-select: none;
  margin: 1px;
  border-radius: ${props => props.borderRadius || BORDER_RADIUS};
  & > * {
    transition: color .15s, box-shadow .15s;
    margin: 1px;
    cursor: pointer;
    overflow: hidden;
    flex: 1;
  }
  & > .disabled {
    color: #CDCDCD;
    cursor: not-allowed;
  }
  & > .disabled > * {
    pointer-events: none;
  }
  & > .selected {
    color: var(--color-golf-green);
  }
`;


const VerticalContainer = styled(Base)`
  flex-direction: column;
  & > *:first-child {
    border-top-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
    border-top-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
  & > *:last-child {
    border-bottom-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
    border-bottom-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
`;


const HorizontalContainer = styled(Base)`
  & > *:first-child {
    border-top-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
    border-bottom-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
  & > *:last-child {
    border-top-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
    border-bottom-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
`;


export default function OptionGroup({ vertical, children, borderRadius, ...props }) {
  const Container = vertical ? VerticalContainer : HorizontalContainer;

  return (
    <Container borderRadius={borderRadius} {...props}>
      {children}
    </Container>
  );
}