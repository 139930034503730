import React, { useState, useMemo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import Branding from './Branding';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import Drawer from '@material-ui/core/Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useSelector } from 'react-redux';
import { getHeaderActionState } from '../reducers/selectors';
import HeaderButton, { BackHeaderButton } from '../components/HeaderButton';

const SPACING = '12px';

const TabContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
`;
const TabContentContainer = styled.div`
  display: flex;
  margin: 0px -${SPACING};
`;

const Container = styled.div`
  z-index: 100;
  background-color: var(--color-background);
  box-shadow: 0 1px 3px 0 #aab7c4;
`;

const UpperContainer = styled.div`
  display: flex;
  flex: none;
  padding: 8px 32px;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 699px) {
    padding-right: 16px;
  }
`;

const StyledTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: var(--color-dark-gray);
  font-weight: bold;
  user-select: none;
`;

const StyledListItemIcon = styled(ListItemIcon)`
  color: var(--color-dark-gray);
`;


function LinkDrawer({ tabs, onClose, activeTab }) {
  return (
    <List>
      {tabs.map((tab, idx) => (
        <ListItem key={idx} alignItems="center">
          <StyledListItemIcon>
            {tab.icon({ active: activeTab && activeTab.href === tab.href })}
          </StyledListItemIcon>
          <NavLink
            key={tab.href}
            to={tab.href}
            onClick={onClose}
            className={({ isActive }) => {
              const classes = "flex-0 text-lg font-semibold whitespace-nowrap mx-3";
              return classes + (isActive ? " text-green-500" : "");
            }}
          >
            {tab.menuTitle || tab.title}
          </NavLink>
        </ListItem>
      ))}
    </List>
  );
}


function ActionButton() {
  const navigate = useNavigate();
  const action = useSelector(getHeaderActionState);

  const onGoBack = useCallback(() => {
    if (action.fn) {
      action.fn();
    } else if (action.url) {
      navigate(action.url);
    } else {
      navigate(-1);
    }
  }, [navigate, action]);

  if (!action) return null;

  if (action.isBack) {
    return (
      <BackHeaderButton
        onClick={onGoBack}
        title={action.title}
      />
    );
  } else {
    return (
      <HeaderButton
        onClick={action.fn}
      >
        {action.title}
      </HeaderButton>
    );
  }
}


export default function Header({ tabs, logoHref }) {
  const isLargeScreen = useMedia('(min-width:760px)');
  const [drawerToggled, setDrawerToggled] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();

  const activeTab = useMemo(() => {
    const href = location.pathname;
    return tabs.find(t => t.href === href);
  }, [tabs, location]);

  const title = activeTab ? activeTab.title : '';

  const hasLogoOnClickRef = useRef(false);

  hasLogoOnClickRef.current = !!(activeTab && activeTab.logoHref);

  const onLogoClick = useCallback(() => {
    if ((activeTab && activeTab.logoHref) || logoHref) {
      navigate(activeTab.logoHref || logoHref);
    }
  }, [activeTab, navigate, logoHref]);

  const navTabs = useMemo(() => {
    return tabs.filter(tab => {
      return tab.header !== false;
    });
  }, [tabs]);

  return (
    <Container>
      <UpperContainer>
        <Branding logo={true} logoProps={{ onClick: hasLogoOnClickRef ? onLogoClick : null }} />

        <Drawer anchor="top" open={drawerToggled} onClose={() => setDrawerToggled(false)}>
          <LinkDrawer tabs={navTabs} activeTab={activeTab} onClose={() => setDrawerToggled(false)} />
        </Drawer>

        {isLargeScreen && (
          <TabContainer>
            <TabContentContainer>
              {navTabs.map((tab) => (
                <NavLink
                  key={tab.href}
                  to={tab.href}
                  className={({ isActive }) => {
                    const classes = "flex-0 text-lg font-semibold whitespace-nowrap mx-3";
                    return classes + (isActive ? " text-green-500" : "");
                  }}
                >
                  {tab.menuTitle || tab.title}
                </NavLink>
                // <TabLink key={idx} to={tab.href} activeStyle={TabActiveStyle}>
                //   {tab.menuTitle || tab.title}
                // </TabLink>
              ))}
            </TabContentContainer>
          </TabContainer>
        )}
        {!isLargeScreen && (
          <>
            <StyledTitle>{title}</StyledTitle>
            <button
              className="btn btn-link focus:ring-0"
              onClick={() => setDrawerToggled(true)}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </>
        )}
      </UpperContainer>
      <ActionButton />
    </Container>
  );
}