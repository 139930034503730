import React, { useRef, useState, useCallback, useMemo } from 'react';
import { useForm, Controller, useWatch } from "react-hook-form";
import PhoneNumberInput, { PhoneNumberInputTransform } from '../../components/Inputs/PhoneNumber';
import PinInput from '../../components/Inputs/Pin';
import * as API from '../../lib/api';
import { toast } from 'react-hot-toast';
import { validatePhoneNumber, validatePin } from '../../lib/validation';


function OnSubmitButton({ control, children, loading }) {
  const { phone_number, pin } = useWatch({ control });

  const disabled = useMemo(() => {
    return loading || !(
      validatePhoneNumber(PhoneNumberInputTransform(phone_number))
      && validatePin(pin)
    );
  }, [loading, phone_number, pin]);

  return (
    <button
      type="submit"
      className={disabled ? "btn btn-disabled" : "btn btn-contained-primary"}
      disabled={disabled}
    >
      {children}
    </button>
  );
}


export default function Credentials({ state, onSubmit }) {
  const phoneInputRef = useRef();
  const pinInputRef = useRef();

  const focusPhoneInput = useCallback(() => {
    if (phoneInputRef.current) {
      phoneInputRef.current.focus();
    }
  }, []);

  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      phone_number: '',
      pin: ''
    }
  });

  const [loading, setLoading] = useState(false);

  const onSubmitForm = useCallback(async (values) => {
    setLoading(true);
    try {
      const user = await API.lookupUser({
        pin: values.pin,
        phone_number: PhoneNumberInputTransform(values.phone_number)
      });
      onSubmit(user);
    } catch (err) {
      setLoading(false);
      reset();
      focusPhoneInput();

      toast.error(err.message || 'Invalid credentials');
    }
  }, [reset, focusPhoneInput, onSubmit]);

  return (
    <>
      <div className="text-center">
        <div className="text-2xl font-bold">{state.site.name}</div>
        <div className="text-xl text-gray-500">Enter phone number and<wbr /> account pin<wbr /> to check in</div>
      </div>
      <div className="rounded shadow bg-white p-3">
        <form className="grid gap-4" onSubmit={handleSubmit(onSubmitForm)}>
          <div>
            <label>Phone Number</label>
            <Controller
              name="phone_number"
              control={control}
              render={({ field: { ref, ...field } }) => (
                <PhoneNumberInput
                  {...field}
                  disabled={loading}
                  ref={phoneInputRef}
                  onInput={(ev) => {
                    if (pinInputRef.current) {
                      const value = PhoneNumberInputTransform(ev.target.value || '');
                      if (value && value.length >= 10 && phoneInputRef.current.selectionStart >= phoneInputRef.current.value.length - 1) {
                        pinInputRef.current.focus();
                        pinInputRef.current.selectionStart = 0;
                        pinInputRef.current.selectionEnd = 0;
                      }
                    }
                  }}
                />
              )}
            />
          </div>
          <div>
            <label>Pin (4-digits)</label>
            <Controller
              name="pin"
              control={control}
              render={({ field }) => (
                <PinInput {...field} disabled={loading} ref={pinInputRef} />
              )}
            />
          </div>
          <OnSubmitButton
            control={control}
            loading={loading}
          >
            {loading ? "Checking credentials" : "Check In"}
          </OnSubmitButton>
        </form>
      </div>
    </>
  );
}