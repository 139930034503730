import { toQueryString } from './qs';
import { ActivityTypeRefs } from '../constants';
import ky, { HTTPError } from 'ky';

const BASE_URL = process.env.REACT_APP_API_BASE_URL || '/api/account';

let getAccessTokenSilently;
export function setgetAccessTokenSilently(fn) {
  getAccessTokenSilently = fn;
}

export async function getAccessToken() {
  return await getAccessTokenSilently();
}


function cleanSearchParams(params) {
  return JSON.parse(JSON.stringify(params));
}

async function handleKyError(err) {
  if (err instanceof HTTPError) {
    const response = await err.response.json();
    throw new Error(response?.error?.message || err.message);
  }
  throw err;
}

export async function authorizedHeaders() {
  const token = await getAccessToken();
  if (!token) {
    throw new Error('Not logged in');
  }
  return {
    Authorization: `Bearer ${token}`,
  };
}

export async function readBuser(uid = 'me') {
  return await ky.get(BASE_URL + `/v1/busers/${uid}`, {
    headers: await authorizedHeaders(),
  }).json().catch(handleKyError);
}

/**
 * updateBuser
 * @param {string} uid 
 * @param {*} data 
 * @returns 
 */
export async function updateBuser(uid, data) {
  return await ky.patch(BASE_URL + `/v1/busers/${uid}`, {
    headers: await authorizedHeaders(),
    json: data,
  }).json().catch(handleKyError);
}

export async function readBusiness(uid = 'me') {
  return await ky.get(BASE_URL + `/v1/businesses/${uid}`, {
    headers: await authorizedHeaders(),
  }).json().catch(handleKyError);
}


export async function listSites({
  business,
  offset, limit, search,
}) {
  return await ky.get(BASE_URL + '/v1/sites', {
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      business,
      offset, limit, search,
    }),
  }).json().catch(handleKyError);
}


export async function listCheckins({
  site = undefined,
  offset = undefined, limit = undefined, search = undefined,
}) {
  return await ky.get(BASE_URL + '/v1/checkins', {
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      site,
      offset, limit, search,
    }),
    timeout: false,
  }).json().catch(handleKyError);
}


export async function createCheckIn({
  user, site_activity, preview, credentials, options,
}) {
  return await ky.post(BASE_URL + '/v1/checkins', {
    headers: await authorizedHeaders(),
    json: {
      user,
      credentials,
      options,
      preview,
      site_activity
    },
  }).json().catch(handleKyError);
}

export async function siteMetrics(siteUID) {
  return await ky.get(BASE_URL + `/v1/sites/${siteUID}/metrics`, {
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      check_in_limit: 100,
    }),
  }).json().catch(handleKyError);
}


export async function listSubscriptions({ offset, limit, search, }) {
  return await ky.get(BASE_URL + '/v1/subscriptions', {
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      offset, limit, search,
    }),
  }).json().catch(handleKyError);
}

export async function lookupUser(input) {
  return await ky.post(BASE_URL + '/v1/users/lookup', {
    headers: await authorizedHeaders(),
    json: input,
  }).json().catch(handleKyError);
}


export async function listActivityTypes({
  site, user,
  offset, limit, search,
}) {
  return await ky.get(BASE_URL + '/v1/activitytypes', {
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      site, user,
      offset, limit, search,
    }),
  }).json().catch(handleKyError);
}


export async function getCheckIn(uid) {
  return await ky.get(BASE_URL + `/v1/checkins/${uid}`, {
    headers: await authorizedHeaders(),
  }).json().catch(handleKyError);
}

export async function updateCheckIn(uid, { success, failure_description, options }) {
  return await ky.patch(BASE_URL + `/v1/checkins/${uid}`, {
    headers: await authorizedHeaders(),
    json: {
      success,
      failure_description,
      options,
    },
  }).json().catch(handleKyError);
}

export async function listSiteActivities({
  active = undefined, business = undefined, site = undefined, user = undefined,
  offset = undefined, limit = undefined, search = undefined,
}) {
  return await ky.get(BASE_URL + '/v1/siteactivities', {
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      active, business, site, user,
      offset, limit, search,
    }),
  }).json().catch(handleKyError);
}


export async function listPaymentPeriods({ offset, limit, search, }) {
  return await ky.get(BASE_URL + '/v1/paymentperiods', {
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      // 
    }),
  }).json().catch(handleKyError);
}


export async function calculateGolfSimulatorPrices({
  site,
  participants,
  hours
}) {
  return await ky.post(BASE_URL + `/v1/checkins/calculators/${ActivityTypeRefs.GolfSimulator}`, {
    headers: await authorizedHeaders(),
    json: {
      site,
      participants,
      hours
    },
  }).json().catch(handleKyError);
}


const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CONNECT_CLIENT_ID;
const STRIPE_REDIRECT_URI = window.location.origin + '/callback/stripe';
const STRIPE_ENDPOINT = 'https://connect.stripe.com/express/oauth/authorize';
const STRIPE_CONNECT_STATE_KEY = 'stripe-connect-state';


export function stripeConnectLink() {
  const connectState = (function createStripeState(length) {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const possibleLength = possible.length;
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possibleLength));
    }
    return text;
  })(10);

  localStorage.setItem(STRIPE_CONNECT_STATE_KEY, connectState);

  const queryStr = toQueryString({
    redirect_uri: STRIPE_REDIRECT_URI,
    client_id: STRIPE_CLIENT_ID,
    state: connectState,
    'stripe_user[business_type]': 'company'
  });

  return `${STRIPE_ENDPOINT}${queryStr}`;
}


export async function handleStripeAuthorizationCode(code, state) {
  const previousState = localStorage.getItem(STRIPE_CONNECT_STATE_KEY);
  localStorage.removeItem(STRIPE_CONNECT_STATE_KEY);

  if (state !== previousState) {
    throw new Error('State does not match');
  }

  return await ky.post(BASE_URL + '/v1/businesses/stripe/oauth/token', {
    headers: await authorizedHeaders(),
    json: {
      code,
    },
  }).json().catch(handleKyError);
}

export async function createStripeLoginLink() {
  console.log(STRIPE_REDIRECT_URI);
  return await ky.get(BASE_URL + '/v1/businesses/stripe/loginlink', {
    headers: await authorizedHeaders(),
    searchParams: cleanSearchParams({
      redirect_url: STRIPE_REDIRECT_URI,
    }),
  }).json().catch(handleKyError);
}