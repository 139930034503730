import styled from 'styled-components';

const BORDER_RADIUS = '6px';
const SPACING = '2px';

const ButtonMatrix = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-template-rows: repeat(${props => props.rows}, 1fr);
  gap: ${props => props.spacing || SPACING};
  // padding: ${props => props.spacing || SPACING};
  padding: 1px;
  border-radius: ${props => props.borderRadius || BORDER_RADIUS};
  
  & > * {
    transition: color .25s, box-shadow .25s;
    cursor: pointer;
    flex: 1;
  }

  & > *:first-child {
    border-top-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
  & > *:first-child > * {
    border-top-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }

  & > *:last-child {
    border-bottom-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
  & > *:last-child > * {
    border-bottom-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }

  & > *:nth-child(${props => props.columns}) {
    border-top-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
  & > *:nth-child(${props => props.columns}) > * {
    border-top-right-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }

  & > *:nth-child(${props => props.columns * (props.rows - 1) + 1}) {
    border-bottom-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }
  & > *:nth-child(${props => props.columns * (props.rows - 1) + 1}) > * {
    border-bottom-left-radius: ${props => props.borderRadius || BORDER_RADIUS};
  }

  & > .disabled {
    color: #CDCDCD;
    cursor: not-allowed;
  }
  & > .disabled > * {
    pointer-events: none;
  }
  & > .selected {
    color: var(--color-golf-green);
  }
`;

export default ButtonMatrix;