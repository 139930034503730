import {
  put,
  fork,
  takeLatest,
} from 'redux-saga/effects';

import * as TransactionActions from '../actions/transaction';
import * as SocketIOActions from '../actions/socketio';
import * as Sentry from '@sentry/react';


function* handleMessageReceived(action) {
  try {
    const message = JSON.parse(action.payload);

    switch (message.type) {
      case 'checkin.success':
        yield put(TransactionActions.checkinSuccess(message.data));
        break;
      case 'checkin.failed':
        yield put(TransactionActions.checkinFailure(message.data));
        break;
      default:
        console.log(message);
        yield null;
    }
  } catch(err) {
    console.log(err);
    Sentry.captureException(err);
  }
}


/** WATCHERS */

function* watchMessageReceived() {
  yield takeLatest(SocketIOActions.MESSAGE, handleMessageReceived);
}


const sagas = [
  fork(watchMessageReceived)
];

export default sagas;