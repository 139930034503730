export default function OptionItem({ selected, onClick, disabled, children, ...props }) {
  return (
    <div
      className={'text-center ' + (disabled ? 'disabled ring-2 ring-gray-200 z-0' : selected ? 'selected ring-4 ring-green-400 z-20' : 'ring-2 ring-gray-300 z-10')}
      onClick={!disabled && !selected ? onClick : undefined}
      {...props}
    >
      {children}
    </div>
  );
}