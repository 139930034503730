import React, { useState } from 'react';
import TextInput from './Text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import IconButton from '@material-ui/core/IconButton';

export default React.forwardRef(function PasswordInput({ showPasswordInitial, ...props }, ref) {
  const [showPassword, setShowPassword] = useState(showPasswordInitial);
  return (
    <div>
      <div className='flex'>
        <TextInput
          type={showPassword ? 'text' : 'password'}
          placeholder='password'
          {...props}
          ref={ref}
        />
        <div className='flex justify-center items-center ml-2'>
          <IconButton
            className='focus:outline-none'
            aria-label='Toggle password visibility'
            onClick={() => setShowPassword(!showPassword)}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="w-[24px] h-[24px]" />
          </IconButton>
        </div>
      </div>
      <div className='text-sm mt-1 text-gray-400 font-medium'>
        Password must be 8+ characters and include a number, a capital letter, and lowercase letter.
      </div>
    </div>
  );
});