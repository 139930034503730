import styled from 'styled-components';

export default styled.div`
  padding: 16px 16px 0px 16px;
  overflow-y: auto;

  flex: 1;

  &:after {
    content: "";
    display: block;
    clear: both;
    padding-bottom: 80px;
  }
`;