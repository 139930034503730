import React, { useReducer, useMemo } from 'react';
import styled from 'styled-components';
import Select from './Select';
import useSWR from 'swr';
import * as API from '../lib/api';
import { formatCurrency } from '../lib/formatting';


const Container = styled.div`
  display: flex;
  & > *:not(:last-child) {
    border-right: 2px solid rgb(241, 241, 241);
  }
`;

const ConfigurationOptions = styled.div`
  display: flex;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
`;

const UnorderedList = styled.ul`
  margin-block-start: 0.5em;
  margin-block-end: 0;
  padding-inline-start: 20px;
  font-size: 12px;
  white-space: nowrap;
`;

const Equation = styled.span`
  font-size: 11px;
  color: #ACACAC;
  margin-top: 4px;
`;

const HOURS_OPTIONS = [...Array(8).keys()].map(value => value + 1).map(value => ({ value, label: value, name: value }));
const PARTICIPANTS_OPTIONS = [...Array(7).keys()].map(value => value + 2).map(value => ({ value, label: value, name: value }));

const INITIAL_STATE = {
  hours: 1,
  participants: 2,
  non_members: 1
};

function reducer(state, { type, payload }) {
  switch (type) {
    case 'hours':
      return { ...state, hours: payload  };
    case 'participants':
      return {
        ...state,
        participants: payload,
        non_members: payload <= state.non_members ? payload - 1 : state.non_members
      };
    case 'non_members':
      return { ...state, non_members: payload };
    default:
      throw new Error('Invalid type: ' + type);
  }
}


export default function GolfSimulatorCalculator({ site }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const {
    hours,
    participants,
    non_members
  } = state;

  const {
    data,
    isValidating,
    error
  } = useSWR(`/calculators/golfsimulator?hours=${hours}&participants=${participants}&site=${site}`, async () => {
    return await API.calculateGolfSimulatorPrices({
      site,
      participants,
      hours
    });
  });

  const equations = useMemo(() => {
    const eqs = {};
    if (data) {
      const {
        discounted_hours,
        non_discounted_hours,
        discounted_price,
        non_discounted_price
      } = data;

      const {
        hourly_rate,
        weekend_discount
      } = data.config;

      const hourly_rate_fmt = formatCurrency(hourly_rate / 100);
      const group_total = discounted_price + non_discounted_price;
      const group_total_fmt = formatCurrency(group_total / 100);
      const participant_price = group_total / participants;
      const participant_price_fmt = formatCurrency(participant_price / 100);
      const non_member_total_fmt = formatCurrency(participant_price * non_members / 100);

      if (discounted_hours > 0 && non_discounted_hours > 0) {
        /** (4hrs x 0.9 + 2hrs) x $39.00 = $218.40 */
        eqs.group_total = `(${discounted_hours}${discounted_hours === 1 ? 'hr' : 'hrs'} x ${(1 - weekend_discount)} + ${non_discounted_hours}${non_discounted_hours === 1 ? 'hr' : 'hrs'}) x ${hourly_rate_fmt} = ${group_total_fmt}`;
      } else if (discounted_hours > 0) {
        /** 4hrs x 0.9 x $39.00 = $140.40 */
        eqs.group_total = `${discounted_hours}${discounted_hours === 1 ? 'hr' : 'hrs'} x ${(1 - weekend_discount)} x ${hourly_rate_fmt} = ${group_total_fmt}`;
      } else {
        /** 2hrs x $39.00 = $78.00 */
        eqs.group_total = `${non_discounted_hours}${non_discounted_hours === 1 ? 'hr' : 'hrs'} x ${hourly_rate_fmt} = ${group_total_fmt}`;
      }

      eqs.participant_price = `${group_total_fmt} / ${participants} = ${participant_price_fmt}`;
      eqs.non_member_total = `${participant_price_fmt} x ${non_members} = ${non_member_total_fmt}`;
    }
    return eqs;
  }, [data, participants, non_members]);

  const nonMemberOptions = [...Array(participants - 1).keys()].map(value => value + 1).map(value => ({ value, label: value, name: value }));

  return (
    <div className="rounded shadow overflow-x-scroll bg-white">
      <Container>
        <div className="p-4">
          <ConfigurationOptions>
            <div className="flex-1">
              <div className="font-medium text-xs whitespace-nowrap">Total Group Hours</div>
              <div className="w-32">
                <Select
                  options={HOURS_OPTIONS}
                  value={HOURS_OPTIONS.find(option => option.value === hours)}
                  onChange={(option) => {
                    dispatch({ type: 'hours', payload: option.value });
                  }}
                />
              </div>
              {data && (
                <Equation>
                  {equations.group_total}
                </Equation>
              )}
            </div>

            <div className="flex-1">
              <div className="font-medium text-xs whitespace-nowrap">Total Group Size</div>
              <div className="w-32">
                <Select
                  options={PARTICIPANTS_OPTIONS}
                  value={PARTICIPANTS_OPTIONS.find(option => option.value === participants)}
                  onChange={(option) => {
                    dispatch({ type: 'participants', payload: option.value });
                  }}
                />
              </div>
              {data && (
                <Equation>
                  {equations.participant_price}
                </Equation>
              )}
            </div>

            <div className="flex-1">
              <div className="font-medium text-xs whitespace-nowrap">Non-Chipper Members</div>
              <div className="w-32">
                <Select
                  options={nonMemberOptions}
                  value={nonMemberOptions.find(option => option.value === non_members)}
                  onChange={(option) => {
                    dispatch({ type: 'non_members', payload: option.value });
                  }}
                />
              </div>
              {data && (
                <Equation>
                  {equations.non_member_total}
                </Equation>
              )}
            </div>
          </ConfigurationOptions>
        </div>
        <div className="p-4">
          <SectionTitle>Non-Member Balance</SectionTitle>
          {data && (
            <UnorderedList
              style={{
                listStyleType: 'none',
                paddingInlineStart: '0px',
                color: isValidating ? '#ACACAC' : 'inherit'
              }}
            >
              <li className="font-bold">{`Discount: ${(+data.non_member_discount).toFixed(1)}%`}</li>
              <li>{`${formatCurrency(data.non_member_price / 100)}/person`}</li>
              <li>{`${formatCurrency(non_members * data.non_member_price / 100)} group balance`}</li>
            </UnorderedList>
          )}
        </div>
        <div className="p-4">
          <SectionTitle>Note About Calculator</SectionTitle>
          <UnorderedList
            style={{
              paddingInlineStart: '20px'
            }}
          >
            <li>On weekends: extra 10% applied</li>
            <li>Only charge Non-Chipper members</li>
            <li>Taxes not included in calculator</li>
          </UnorderedList>
          {error && (
            <div className="text-red-500">{error.message}</div>
          )}
        </div>
      </Container>
    </div>
  );
}