import Page from '../components/Page';
import { APP_URLS } from '../constants';
import { useNavigate, useParams } from 'react-router-dom';
import useHeader from '../hooks/useHeader';
import LoadingBar from '../components/LoadingIndicator';
import EditorError from '../components/EditorError';
import CheckInTicket from './CheckIn/Ticket';
import * as API from '../lib/api';
import useSWR from 'swr';
import Modal from '../components/Modal';
import useConfirmation from '../hooks/useConfirmation';
import ConfirmCancelCheckIn from '../components/Confirmations/CancelCheckIn';

const ENTITY_HEADER_ID = 'checkin';
const ENTITY_HEADER_BACK_TITLE = 'Dashboard';
const GOBACK_URL = APP_URLS.DASHBOARD;


export default function Editor() {
  const { uid } = useParams();

  const navigate = useNavigate();

  useHeader(ENTITY_HEADER_ID, {
    action: {
      title: ENTITY_HEADER_BACK_TITLE,
      isBack: true,
      fn: () => navigate(GOBACK_URL)
    }
  });

  const { data, error, mutate } = useSWR(`/checkins/${uid}`, async () => {
    return await API.getCheckIn(uid);
  });

  const confirmation = useConfirmation();

  return (
    <Page>
      <div className="px-4 grid gap-4">
        {!data && !error && (
          <LoadingBar show={!data} delay={400} />
        )}
        
        <EditorError error={error} onRefresh={mutate} />

        {data && (
          <>
            <div className="flex justify-center">
              <CheckInTicket checkIn={data} />
            </div>

            {data.success && (
              <div className="flex justify-center mt-4">
                <button
                  className="btn btn-outlined-primary"
                  onClick={() => confirmation.open('cancel', data)}
                >
                  Cancel Check In
                </button>
              </div>
            )}
          </>
        )}
      </div>
      
      <Modal
        isOpen={confirmation.active}
        onRequestClose={confirmation.hide}
      >
        <>
          {confirmation.type === 'cancel' && (
            <ConfirmCancelCheckIn
              checkIn={data}
              onDismiss={confirmation.hide}
              mutate={mutate}
            />
          )}
        </>
      </Modal>
    </Page>
  );
}