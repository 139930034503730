import { action } from './common';

const BASE = 'APP/';

export const STOP_LOADING = `${BASE}STOP_LOADING`;
export const stopLoading = () => action(STOP_LOADING);

export const SET_SELECTED_SITE = `${BASE}SET_SELECTED_SITE`;
export const setSelectedSite = (site) => action(SET_SELECTED_SITE, site);

export const SET_ERROR = `${BASE}SET_ERROR`;
export const setError = err => action(SET_ERROR, err);

export const REGISTER_HEADER = `${BASE}REGISTER_HEADER`;
export const registerHeader = (id, { title, action: actionFn }) => action(REGISTER_HEADER, { id, title, action: actionFn });
export const CLEAR_HEADER = `${BASE}CLEAR_HEADER`;
export const clearHeader = (id) => action(CLEAR_HEADER, id);