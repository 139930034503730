import { useAuth0 } from '@auth0/auth0-react';
import MainLayout from '../components/MainLayout';
import Branding from '../components/Branding';

function LogoutButton() {
  const { logout } = useAuth0()

  return (
    <button
      className='btn btn-outlined-primary'
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      Log Out
    </button>
  );
}

export default function ErrorPage({ error }) {
  return (
    <MainLayout
      Header={{ className: 'justify-between' }}
      card
      header={(
        <>
          <Branding logo={true} name={true} />
        </>
      )}
      title={(
        <>
          <div className='text-3xl font-extrabold'>Error</div>
          <div className='text-lg'>{error && error.message ? error.message : error || 'Something went wrong'}</div>
        </>
      )}
      content={(
        <div className='grid gap-8'>
          <button
            className='btn btn-contained-primary'
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
          <LogoutButton />
        </div>
      )}
    />
  );
}