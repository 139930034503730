import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import Routes from './routes';
// import configureStore from './store/configureStore';
// import { Route, Router } from 'react-router-dom';
// import App from './containers/App';
import store from './store';
import rootSaga from './sagas';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import ReactModal from "react-modal";
import ErrorPage from './pages/ErrorPage';
import { Toaster } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import * as API from './lib/api';
import { BrowserRouter, useNavigate } from 'react-router-dom';

if (process.env.REACT_APP_SENTRY_DSN && process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, environment: process.env.REACT_APP_SENTRY_ENV });
}

// const store = configureStore();
store.runSaga(rootSaga);

const rootElement = document.getElementById('root');

ReactModal.setAppElement(rootElement);

function onRedirectCallback(navigate, appState) {
  // console.log(appState);
  // console.log(arguments);
  // throw 1;
  // If using a Hash Router, you need to use window.history.replaceState to
  // remove the `code` and `state` query parameters from the callback url.
  // window.history.replaceState({}, document.title, window.location.pathname);
  navigate((appState && appState.returnTo) || window.location.pathname, { replace: true });
  // history.go(0);
};

function Auth0AccessTokenHelper() {
  const { getAccessTokenSilently } = useAuth0();
  API.setgetAccessTokenSilently(getAccessTokenSilently);
  return null;
}

const root = createRoot(rootElement);

function Root() {
  const navigate = useNavigate()
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin + '/callback/auth'}
      onRedirectCallback={(...args) => onRedirectCallback(navigate, ...args)}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <Auth0AccessTokenHelper />
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Toaster
            toastOptions={{
              success: {
                iconTheme: {
                  primary: '#10B981'
                }
              }
            }}
          />
          <Sentry.ErrorBoundary fallback={ErrorPage}>
            <Routes />
            {/* <App /> */}
            {/* <div>test</div> */}
            {/* <Router history={history}>
            <Route path="/" component={App} />
          </Router> */}
          </Sentry.ErrorBoundary>
        </ThemeProvider>
      </Provider>
    </Auth0Provider>
  );
}

root.render((
  <BrowserRouter>
    <Root />
  </BrowserRouter>
));


reportWebVitals();

function handleRegistration(registration) {
  try {
    if (registration) {
      const waitingServiceWorker = registration.waiting;
      if (waitingServiceWorker) {
        waitingServiceWorker.addEventListener("statechange", event => {
          if (event.target.state === "activated") {
            window.location.reload();
          }
        });
        waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
      }
    }
  } catch (err) {
    Sentry.captureException(err);
    console.log(err);
  }
}

serviceWorkerRegistration.register({
  onSuccess: registration => {
    function checkForUpdate() {
      registration.update();
    }

    setInterval(checkForUpdate, 20 * 60 * 1000);
    checkForUpdate();

    handleRegistration(registration);
  },
  onUpdate: registration => {
    handleRegistration(registration);
  }
});

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  window.addEventListener('load', async () => {
    try {
      const registration = await navigator.serviceWorker.ready;
      handleRegistration(registration);
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
    }
  });
}