import styled from 'styled-components';

export const InfoLabel = styled.span`
  font-size: 20px;
  font-weight: 600;
  color: var(--color-light-gray);
`;

export const InfoValue = styled.div`
  font-size: 28px;
  color: var(--color-dark-gray);
  margin: 3px 0px;
`;