import React from 'react';
import { useRifm } from 'rifm';
import { AsYouType } from 'libphonenumber-js/min';
import clsx from 'clsx';

const accept = /\d+/g;

const parseDigits = string => (string.match(/\d+/g) || []).join('');

export const formatPhoneNumber = string => {
  const digits = parseDigits(string).substr(0, 10);
  return new AsYouType('US').input(digits);
};

export const PhoneNumberInputTransform = value => (value || '').replace(/\D/g, '');

export default React.forwardRef(function PhoneNumberInput({ label, placeholder, value, onChange, className, ...props }, ref) {
  value = value || '';

  const rifm = useRifm({
    value,
    onChange,
    format: formatPhoneNumber,
    accept,
    mask: value.length < 6 && /[^\d]+/.test(value[3])
      ? undefined
      : value.length >= 14
  });

  return (
    <input
      className={clsx("field-input", className, props.disabled && "text-gray-500")}
      type="tel"
      placeholder="(123) 456-7890"
      {...props}
      {...rifm}
      ref={ref}
    />
  );
});