import React, { useState, useMemo } from 'react';
import useInterval from '../hooks/useInterval';
import { ActivityTypeRefs } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';

const MINUTES_THRESHOLD = 60 * 60 * 1000;

function getDate() {
  return new Date();
  // return new Date('10/24/2019 11:56 AM');
}

function sameDay(d1, d2) {
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

function formatFullDate(date) {
  const currentYear = getDate().getFullYear();
  const year = date.getFullYear();
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  const time = formatAMPM(date);
  if (year !== currentYear) {
    return `${month} ${day}, ${year}, ${time}`;
  }
  return `${month} ${day}, ${time}`;
}

function dateTimeRender(date, { current, yesterday }) {
  date = new Date(date);

  const diff = current - date;
  if (diff < 60 * 1000) {
    return 'Just now';
  } if (diff < MINUTES_THRESHOLD) {
    const minutes = (diff / 60 / 1000) | 0;
    return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
  } else if (sameDay(date, current)) {
    return `Today, ${formatAMPM(date)}`;
  } else if (sameDay(date, yesterday)) {
    return `Yesterday, ${formatAMPM(date)}`;
  } else {
    return formatFullDate(date);
  }
}

function getDatePrior(d) {
  const yd = new Date(d);
  return new Date(yd.setDate(yd.getDate() - 1));
}


function pluralize(count, singular, plural) {
  return `${count} ${count === 1 ? singular : plural}`;
}


function Options({ checkIn }) {
  const options = checkIn.options || {};
  const activityType = checkIn.Service.Access.Activity.ActivityType;
  switch (activityType.ref) {
    case ActivityTypeRefs.Golf:
      return (
        <div>
          <div>Paid & Ready to Play</div>
          <div className="text-sm">
            <div>Golf</div>
            <div>{`${options.holes}-holes | ${options.golf_cart ? 'Golf Cart' : 'Walking'}`}</div>
          </div>
        </div>
      );
    case ActivityTypeRefs.GolfSimulator:
      return (
        <div>
          <div>Paid & Ready to Play</div>
          <div className="text-sm">
            <div>Golf Simulator</div>
            <div>{`${pluralize(+options.hours, 'hour', 'hours')} | ${pluralize(options.participants, 'participant', 'participants')}`}</div>
          </div>
        </div>
      );
    default:
      return (
        <div>
          <div>Paid & Ready to Play</div>
          <div className="text-sm">
            <div>{activityType.name}</div>
          </div>
        </div>
      );
  }
}

function AvatarIconContainer({ children, ...props }) {
  return (
    <div className="w-10 h-10 min-w-[40px] mr-2 flex justify-center items-center" {...props}>
      {children}
    </div>
  );
}

function getInitials(name) {
  const names = (name || '').split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

function UserInfo({ checkIn, date, yesterday, showSite }) {
  const user = checkIn.User;

  const initials = user.profile_image_thumbnail ? null : getInitials(user.full_name);

  return (
    <div className="flex items-center">
      <AvatarIconContainer>
        <Avatar src={user.profile_image_thumbnail} alt={user.full_name || user.email || 'profile'}>
          {initials}
        </Avatar>
      </AvatarIconContainer>
      <div>
        <div>{user.full_name || user.email}</div>
        {showSite && (
          <div className="text-sm">{checkIn.Site.name}</div>
        )}
        <div className="text-sm">{dateTimeRender(checkIn.date, { current: date, yesterday })}</div>
      </div>
    </div>
  );
}

function SuccessIcon() {
  return (
    <AvatarIconContainer>
      <FontAwesomeIcon icon={faCircleCheck} size="lg" className="text-green-500" />
    </AvatarIconContainer>
  );
}

function UnsuccessIcon() {
  return (
    <AvatarIconContainer>
      <FontAwesomeIcon icon={faCircleXmark} size="lg" className="text-red-500" />
    </AvatarIconContainer>
  );
}

function ActivityInfo({ checkIn, showSite }) {
  return (
    <div className="flex items-center mt-1">
      {checkIn.success && (
        <>
          <SuccessIcon />
          <Options
            checkIn={checkIn}
            showSite={showSite}
          />
        </>
      )}
      {!checkIn.success && (
        <>
          <UnsuccessIcon />
          <div>{checkIn.failure_description}</div>
        </>
      )}
    </div>
  );
}

export default function CheckInList({ checkIns, showSite, onRowClick, onCancel }) {
  const [date, setDate] = useState(getDate());
  const yesterday = useMemo(() => getDatePrior(date), [date]);

  useInterval(
    () => setDate(getDate()),
    checkIns && checkIns.length > 0 ? 5000 : null
  );

  if (!checkIns) {
    return null;
  }

  if (checkIns.length === 0) {
    return null;
  }

  return (
    <div className="rounded shadow bg-white overflow-auto">
      <List>
        {checkIns.map((checkIn) => (
          <ListItem
            key={checkIn.uid}
            className="flex-wrap"
            divider={true}
            button
            onClick={() => onRowClick && onRowClick(checkIn)}
          >
            <div className="flex flex-1 justify-between">
              <div className="min-w-[212px]">
                <UserInfo
                  checkIn={checkIn}
                  date={date}
                  yesterday={yesterday}
                  showSite={showSite}
                />
                <ActivityInfo checkIn={checkIn} />
              </div>

              {checkIn.success && (
                <div className="flex flex-col justify-center">
                  <button
                    className="btn btn-outlined-primary text-sm"
                    onClick={(e) => { e.stopPropagation(); onCancel && onCancel(checkIn); }}
                  >
                    Cancel Check-In
                  </button>
                </div>
              )}
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
}