import store from './store';
import * as SocketIOActions from './actions/socketio';
import { io } from 'socket.io-client';

let _uri;
let _path;
let _query;

let _socket;

export function connect({ uri, path, query }) {
  _uri = uri;
  _path = path;
  _query = query;

  /** ensure existing socket is closed */
  disconnect(true);

  if (uri) {
    _socket = io(uri, {
      path,
      query,
    });
  } else {
    _socket = io({
      path,
      query,
    });
  }
  

  store.dispatch(SocketIOActions.connecting());

  _socket.on('connect', () => {
    store.dispatch(SocketIOActions.open());

    /**
     * @type {import('socket.io-client').Socket}
     */
    const s = _socket;
    s.onAny(function (event, ...args) {
      store.dispatch(SocketIOActions.message(event));
    });
  });

  _socket.on('error', err => {
    store.dispatch(SocketIOActions.error(err));
  });

  _socket.on('disconnecting', reason => {
    store.dispatch(SocketIOActions.disconnecting(reason));
  });

  _socket.on('reconnecting', () => {
    store.dispatch(SocketIOActions.connecting());
  });

  _socket.on('disconnect', reason => {
    // if (reason === 'io server disconnect') {
    //   // the disconnection was initiated by the server, you need to reconnect manually
    //   socket.connect();
    // }
    // console.log('disconnect', reason);
    store.dispatch(SocketIOActions.closed(reason));
  });
}


export function ensureConnected() {
  if (_socket && !_socket.connected) {
    connect({
      uri: _uri,
      path: _path,
      query: _query
    });
  }
}


export function disconnect(removeListeners) {
  if (_socket && !_socket.disconnected) {
    _socket.disconnect();
    if (removeListeners === true) {
      _socket.removeAllListeners();
    }
  }
}