import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import Settings from './Settings';
import Metrics from './Metrics';
import Dashboard from './Dashboard';
import CheckIn from './CheckIn';
import StripeSetup from './StripeSetup';
import ErrorPage from './ErrorPage';
import Subscriptions from './Subscriptions';
import GolfSimulatorGuide from './GolfSimulatorGuide';
import GolfHelpGuide from './GolfHelpGuide';
import IncidentReport from './IncidentReport';
import Header from '../components/Header';
import Loading from '../components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faArrowTrendUp, faUserGroup, faGauge } from '@fortawesome/free-solid-svg-icons';
import ChipperIcon from '../icons/Chipper';
import { hasOneRole, getBuserRoleUIDs } from '../lib/helpers';
import * as AppActions from '../actions/app';
import { APP_URLS } from '../constants';
import { useAuth0 } from '@auth0/auth0-react';
import Login from './Login';
import useMeSWR from '../hooks/useMeSWR';
import * as SocketIO from '../socketio';
import * as API from '../lib/api';

const MESSAGE_API_DOMAIN = process.env.REACT_APP_MESSAGE_API_DOMAIN;
const MESSAGE_API_PATH = process.env.REACT_APP_MESSAGE_API_PATH;

const InactiveIconStyle = { color: 'var(--color-dark-gray)' };
const ActiveIconStyle = { color: 'var(--color-golf-green)' };

function IconContainer({ children }) {
  return (
    <div className="w-[24px] h-[24px] flex items-center justify-center">
      {children}
    </div>
  );
}

const ROUTES = [
  {
    title: 'Settings',
    href: APP_URLS.SETTINGS,
    header: true,
    component: Settings,
    icon: ({ active }) => <IconContainer><FontAwesomeIcon icon={faGear} style={active ? ActiveIconStyle : InactiveIconStyle} size="lg" /></IconContainer>
  },
  {
    title: 'Metrics',
    href: APP_URLS.METRICS,
    header: true,
    component: Metrics,
    icon: ({ active }) => <IconContainer><FontAwesomeIcon icon={faArrowTrendUp} style={active ? ActiveIconStyle : InactiveIconStyle} size="lg" /></IconContainer>,
    // icon: ({ active }) => <TrendingUpIcon style={active ? ActiveIconStyle : InactiveIconStyle} />,
    roles: [
      'admin'
    ]
  },
  {
    title: 'Subscribers',
    href: APP_URLS.SUBSCRIBERS,
    header: true,
    component: Subscriptions,
    icon: ({ active }) => <IconContainer><FontAwesomeIcon icon={faUserGroup} style={active ? ActiveIconStyle : InactiveIconStyle} size="lg" /></IconContainer>,
  },
  {
    title: 'Dashboard',
    href: APP_URLS.DASHBOARD,
    header: true,
    component: Dashboard,
    icon: ({ active }) => <IconContainer><FontAwesomeIcon icon={faGauge} style={active ? ActiveIconStyle : InactiveIconStyle} size="lg" /></IconContainer>,
    // icon: ({ active }) => <DashboardIcon style={active ? ActiveIconStyle : InactiveIconStyle} />,
    primary: true
  },
  {
    title: 'Check In',
    href: APP_URLS.CHECKIN,
    header: true,
    component: CheckIn,
    icon: ({ active }) => <IconContainer><ChipperIcon style={active ? ActiveIconStyle : InactiveIconStyle} size={24} /></IconContainer>,
  },
  {
    title: 'Golf Simulator Guide',
    href: APP_URLS.GOLF_SIMULATOR_GUIDE,
    component: GolfSimulatorGuide
  },
  {
    title: 'Golf Guide',
    href: APP_URLS.GOLF_HELP_GUIDE,
    component: GolfHelpGuide
  },
  {
    title: 'Incident Report',
    href: APP_URLS.INCIDENT_REPORT,
    component: IncidentReport
  }
];

const DEFAULT_TAB_HREF = ROUTES.find((t) => t.primary === true).href;

async function contectWebsocket() {
  let urlObj;
  const loc = window.location;
  const protocol = loc.protocol === 'https:' ? 'wss:' : 'ws:';
  if (process.env.NODE_ENV === 'development') {
    // const loc = window.location;
    
    urlObj = {
      // uri: `${protocol}//${loc.host}`,
      // uri: MESSAGE_API_DOMAIN ? `${protocol}//${MESSAGE_API_DOMAIN || loc.host}` : undefined,
      path: MESSAGE_API_PATH || '/api/message/io',
      query: {
        access_token: await API.getAccessToken()
      }
    };
  } else {
    // const loc = window.location;
    // const protocol = loc.protocol === 'https:' ? 'wss:' : 'ws:';
    urlObj = {
      uri: `${protocol}//${MESSAGE_API_DOMAIN || loc.host}`,
      path: MESSAGE_API_PATH || '/api/message/io',
      query: {
        access_token: await API.getAccessToken()
      }
    };
  }
  SocketIO.connect(urlObj);
}

export default function App() {
  const { isLoading, isAuthenticated, error: authError } = useAuth0();

  const dispatch = useDispatch();

  const app = useSelector((state) => state.app);
  const meSWR = useMeSWR();

  const setupRef = useRef(false);
  
  useEffect(() => {
    if (meSWR.data && !setupRef.current) {
      if (meSWR.data.Business.setup) {
        setupRef.current = true;
        dispatch(AppActions.setSelectedSite(meSWR.data.DefaultSite?.uid));
        contectWebsocket();
      }
    }
  }, [dispatch, meSWR]);

  const permittedRoutes = useMemo(() => {
    return ROUTES.filter(route => {
      if (!Array.isArray(route.roles) || route.roles.length === 0) {
        return true;
      } else if (meSWR.data) {
        return hasOneRole(getBuserRoleUIDs(meSWR.data), route.roles);
      }
      return false;
    });
  }, [meSWR.data]);

  const tabs = useMemo(() => {
    return permittedRoutes.filter(tab => {
      switch (typeof tab.header) {
        case 'boolean':
          return tab.header === true;
        // case 'function':
        //   return tab.header({ /* user */ });
        default:
          return false;
      }
    });
  }, [permittedRoutes]);

  if (app.error || authError) {
    return (
      <ErrorPage error={app.error || authError} />
    );
  }

  if (isLoading || app.loading) {
    return (
      <Loading title={app.loadingText} />
    );
  }

  if (!isAuthenticated) {
    return (
      <Login />
    );
  }

  if (meSWR.data?.Business.setup === false) {
    return (
      <StripeSetup />
    );
  }

  return (
    <div className="flex flex-col flex-1 bg-gray-100">
      <Header tabs={tabs} />
      {meSWR.data && (
        <Routes>
          {permittedRoutes.map((route, idx) => (
            <Route key={idx} path={`${route.href}/*`} element={<route.component />} />
          ))}
          <Route path='*' element={<Navigate replace to={DEFAULT_TAB_HREF} />} />
        </Routes>
      )}
    </div>
  );
}