import React, { useState } from 'react';
import ChipperIcon from '../icons/Chipper';
import styled, { keyframes } from 'styled-components';
import { useTimeoutFn } from 'react-use';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: var(--color-background);
  animation: ${fadeIn} 0.4s ease;
`;

const LoadingFiller = styled.div`
  display: flex;
  flex: ${props => props.flex ? props.flex : 1};
  justify-content: flex-start;
`;

const StyledTitle = styled.span`
  color: var(--color-dark-gray);
  margin-top: 8px;
  opacity: 1;
  animation: ${fadeIn} 1s ease;
`;

function unregister() {
  console.log('Unregistering service workers');
  if (window.navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations()
      .then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
        console.log('Unregistered... redirecting');
        setTimeout(() => {
          window.location.href = "/";
          window.location.reload();
        }, 1000);
      });
  } else {
    console.log('No service workers');
    setTimeout(() => {
      window.location.href = "/";
      window.location.reload();
    }, 1000);
  }
}

export default function Loading({ title }) {
  const [showUnregister, setShowUnregister] = useState(false);

  useTimeoutFn(
    () => setShowUnregister(true),
    25000
  );

  return (
    <Container>
      <LoadingFiller flex={3} />
      <ChipperIcon size={100} color={'var(--color-dark-gray)'} />
      <LoadingFiller flex={4}>
        {title && (
          <StyledTitle>{title}</StyledTitle>
        )}
        {showUnregister && (
          <div className="flex flex-col items-center">
            <div className="mb-1">App might be stuck</div>
            <button
              className="btn btn-contained-primary"
              onClick={unregister}
            >
              Reload App
            </button>
          </div>
        )}
      </LoadingFiller>
    </Container>
  );
};
