import React from 'react';
import styled from 'styled-components';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import InputField from '../components/InputField';
import LoadingIndicator from '../components/LoadingIndicator';
import Page from '../components/Page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import useSWR from 'swr';
import * as API from '../lib/api';
import usePagination from '../hooks/usePagination';


const ContentContainer = styled.div`
  min-height: min-content;
  text-align: left;
  align-items: center;
  margin: 0 auto;
  max-width: 1000px;
`;

const useStyles = makeStyles({
  tableWrapper: {
    padding: '0 12px'
  },
  tableRow: {
    height: '40px'
  },
  tableCell: {
    whiteSpace: 'nowrap'
  },
  tableCellHeader: {
    fontSize: '16px',
    color: 'var(--color-dark-gray)'
  },
  profileThumbnailCell: {
    paddingRight: '0px',
    // width: '40px'
    minWidth: '62px !important'
  },
  profileThumbnail: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    verticalAlign: 'middle',
    objectFit: 'cover'
  },
  profileThumbnailImg: {
    backgroundColor: '#ACACAC'
  }
});


export default function Subscriptions() {
  const classes = useStyles();
  const pagination = usePagination({ syncLocationSearch: false });

  const subscriptionsSWR = useSWR(`/subscriptions${pagination.queryString}`, () => {
    return API.listSubscriptions(pagination.query);
  });

  const listItems = subscriptionsSWR.data || [];

  const allowPageNext = !subscriptionsSWR.isValidating && subscriptionsSWR.data?.length > pagination.query.limit;

  return (
    <Page>
      <ContentContainer>
        <div className="rounded shadow bg-white">
          <div className="flex justify-between items-center p-3">
            <div className="mr-2">
              <InputField
                label="Search"
                placeholder="search"
                value={pagination.search}
                onChange={(ev) => pagination.setSearch(ev.target.value)}
              />
            </div>
            <LoadingIndicator show={!subscriptionsSWR.data && subscriptionsSWR.isValidating} />
          </div>

          <div className={classes.tableWrapper}>
            <TableContainer className="rounded shadow bg-white overflow-auto">
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellHeader} ${classes.profileThumbnailCell}`}>
                    </TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellHeader}`}>
                      Name
                    </TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellHeader}`}>
                      Phone (last 4)
                    </TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.tableCellHeader}`}>
                      Access
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionsSWR.error && (
                    <TableRow>
                      <TableCell className={classes.cell}>
                        <div className="text-red-500">
                          {subscriptionsSWR.error.message || 'Unexpected Error'}
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {!subscriptionsSWR.isValidating && !subscriptionsSWR.error && listItems.length === 0 && (
                    <TableRow>
                      <TableCell className={classes.cell}>
                        No Results
                      </TableCell>
                    </TableRow>
                  )}
                  {listItems.length > 0 && listItems.map((record, idx) => (
                    <TableRow key={idx} className={classes.tableRow} hover={true}>
                      <TableCell className={`${classes.tableCell} ${classes.profileThumbnailCell}`}>
                        {record.User.profile_image_thumbnail && (
                          <img className={`${classes.profileThumbnail} ${classes.profileThumbnailImg}`} src={record.User.profile_image_thumbnail} alt="profile" />
                        )}
                        {!record.User.profile_image_thumbnail && (
                          <FontAwesomeIcon icon={faCircleUser} className="w-[40px] h-[40px]" style={{ color: '#ACACAC' }} />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {record.User.full_name || '-'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {record.User.phone_number ? `...${record.User.phone_number}` : '-'}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {record.Access.name}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="flex justify-end p-4">
            <button
              disabled={pagination.page <= 1}
              className={pagination.page <= 1 ? "btn btn-disabled" : "btn btn-contained-primary"}
              onClick={!subscriptionsSWR.isValidating ? () => pagination.setPage(pagination.page - 1) : null}
            >
              Previous
            </button>
            <button
              className={"ml-4 " + (!allowPageNext ? "btn btn-disabled" : "btn btn-contained-primary")}
              onClick={allowPageNext ? () => pagination.setPage(pagination.page + 1) : null}
            >
              Next
            </button>
          </div>
        </div>
      </ContentContainer>
    </Page>
  );
}