import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import App from './pages/App';
import * as API from './lib/api';
import * as qs from './lib/qs'; 

const HOME_PATH = '/dashboard';


async function handleStripeCallback({ location }) {
  const params = qs.parseQueryString(location.search);

  const result = await API.handleStripeAuthorizationCode(
    params.code,
    params.state
  );

  return result;
}

function StripeCallback() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      await handleStripeCallback({ location });
      navigate(HOME_PATH, { replace: true });
    })();
  }, [location, navigate]);

  return null;
}

export default function Routes2() {
  return (
    <Routes>
      <Route path="/callback/stripe" element={<StripeCallback />} />
      <Route path="*" element={<App />} />
    </Routes>
  );
}
