import React, { useMemo } from 'react';
import UserProfileImage from './UserProfileImage';

export default function UserProfile({ user = {}, children, ...props }) {
  const name = useMemo(() => {
    if (user.full_name) return user.full_name;
    return [user.first_name, user.last_name].filter(v => !!v).join(' ');
  }, [user.full_name, user.first_name, user.last_name]);

  return (
    <div className="flex items-center pr-4" {...props}>
      <UserProfileImage src={user.profile_image} size={92} />
      <div className="flex flex-col justify-center ml-4 flex-1">
        <div className="text-xl font-medium">{name}</div>
        {children}
      </div>
    </div>
  );
}
