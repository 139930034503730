export function redirectTo(url) {
  window.location.href = url;
}

function devicePixelRatio() {
  const ratio = window.devicePixelRatio;
  if (Number.isFinite(ratio)) {
    return ratio;
  }
  return null;
}

export function getProfilePictureURL(url, width = 156, height = 156) {
  url = new URL(url);
  const pixelRatio = devicePixelRatio();
  url.searchParams.append('tr', `w-${width},h-${height}${pixelRatio ? `,dpr-${pixelRatio}` : ''}`);
  return url.toString();
}


export function getBuserRoleUIDs(buser) {
  if (buser) {
    return buser.BuserRole.map((r) => r.Role.uid);
  }
  return [];
}


export function hasOneRole(userRoles = [], roles) {
  for (let i = 0; i < userRoles.length; i++) {
    if (roles.indexOf(userRoles[i].name || userRoles[i]) >= 0) {
      return true;
    }
  }
  return false;
}


export function hasAllRoles(userRoles, roles) {
  for (let i = 0; i < userRoles.length; i++) {
    if (roles.indexOf(userRoles[i].name || userRoles[i]) < 0) {
      return false;
    }
  }
  return true;
}


export function canAccessSiteMetrics(userRoles) {
  return hasOneRole(userRoles, ['admin']);
}


export function canAccessStripeAccount(userRoles) {
  return hasOneRole(userRoles, ['admin']);
}