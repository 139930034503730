import { GENERIC_USER_PRONOUN } from '../../constants';
import Ticket from './Ticket';


export default function Complete({ state, onReset }) {
  return (
    <>
      <div className="text-center">
        <div className="text-2xl font-bold">Check In Complete</div>
        <div className="text-xl text-gray-500">{`${GENERIC_USER_PRONOUN} is checked in and ready to play`}</div>
      </div>

      <div className="grid gap-6 justify-items-center">
        <Ticket checkIn={state.checkIn} />

        <button
          className="btn btn-contained-primary"
          onClick={onReset}
        >
          {`Next ${GENERIC_USER_PRONOUN}`}
        </button>
      </div>
    </>
  );
}