

const Formatters = {
  Currency: {
    'usd': new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    })
  }
};

export function formatCurrency(amount, currency = 'usd') {
  let formatter = Formatters.Currency[currency];
  if (formatter) {
    return formatter.format(amount);
  }

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency.toUpperCase(),
  });

  if (formatter) {
    Formatters.Currency[currency] = formatter;
    return formatter.format(amount);
  }

  return '';
}