import {
  action
} from './common';

const BASE = `TRANSACTION/`;

export const CHECKIN_SUCCESS = `${BASE}CHECKIN_SUCCESS`;
export const CHECKIN_FAILURE = `${BASE}CHECKIN_FAILURE`;

export const checkinSuccess = (checkin) => action(CHECKIN_SUCCESS, checkin);
export const checkinFailure = (checkin) => action(CHECKIN_FAILURE, checkin);