

export const APP_URLS = {
  SETTINGS: '/settings',
  METRICS: '/metrics',
  SUBSCRIBERS: '/subscribers',
  DASHBOARD: '/dashboard',
  CHECKIN: '/checkin',
  GOLF_SIMULATOR_GUIDE: '/golf-simulator-guide',
  GOLF_HELP_GUIDE: '/golf-help-guide',
  INCIDENT_REPORT: '/incident-report'
};


export const ActivityTypeRefs = {
  Golf: 'golf',
  GolfSimulator: 'golfsimulator'
};


export const GENERIC_USER_PRONOUN = 'Golfer';