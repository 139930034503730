export default function PickerOptionItem({ title, description, icon }) {
  return (
    <div className="flex p-3">
      <div className="flex flex-col items-start flex-1">
        <div className="text-xl">{title}</div>
        <div className="text-lg text-gray-500">{description}</div>
      </div>
      {icon && (
        <div className="flex flex-col justify-center">
          {icon}
        </div>
      )}
    </div>
  );
}