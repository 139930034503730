import { useMemo, useCallback, useState } from 'react';
import OptionGroup from '../../../components/OptionGroup';
import OptionItem from '../../../components/OptionItem';
import GolfCartIcon from '../../../icons/GolfCart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalking } from '@fortawesome/free-solid-svg-icons';
import { formatCurrency } from '../../../lib/formatting';
import { ActivityTypeOptionItem } from './common';
// import * as ToggleGroup from '@radix-ui/react-toggle-group';


export default function Configurator({ state, preview, onPreview }) {
  const [options, setOptions] = useState({});

  const siteActivity = state.site_activity;

  const onSelectOption = useCallback((option) => {
    const nextOptions = { ...options, ...option };
    setOptions(nextOptions);
    if (nextOptions.holes != null && nextOptions.golf_cart != null) {
      onPreview(nextOptions);
    }
  }, [options, onPreview]);

  const {
    golfCartFee,
    walkingFee
  } = useMemo(() => {
    let golfCartFeeDescription, walkingFeeDescription;

    if (siteActivity.config.allows_walking) {
      walkingFeeDescription = 'Free';
    } else {
      walkingFeeDescription = 'Golf Cart Required';
    }

    if (!siteActivity.config.allows_golf_cart) {
      golfCartFeeDescription = 'Walking Only';
    } else {
      if (preview && preview.invoice) {
        if (options.golf_cart) {
          const golfCartFeeLine = preview.invoice.items.find(item => item.ref === 'golf_cart');

          if (golfCartFeeLine) {
            golfCartFeeDescription = formatCurrency(
              golfCartFeeLine.amount / 100,
              golfCartFeeLine.currency
            );
          } else {
            golfCartFeeDescription = 'Free';
          }
        }
      }
    }

    return {
      golfCartFee: golfCartFeeDescription,
      walkingFee: walkingFeeDescription
    };
  }, [preview, siteActivity, options]);

  return (
    <div className="grid gap-4">
      <div>
        <div className="text-xl font-medium">
          How many holes?
        </div>
        {/* <div>
          <ToggleGroup.Root type='single' className='rounded bg-red-500 inline-flex first:ml-0'>
            <ToggleGroup.Item value='9' className='rounded-l bg-white py-2 px-3 focus:outline-none focus:ring-2 focus:ring-green-400 focus:z-10'>
              9
            </ToggleGroup.Item>
            <ToggleGroup.Item value='18' className='ml-[1px] bg-white bg-white py-2 px-3 focus:outline-none focus:ring-2 focus:ring-green-400 focus:z-10'>
              18
            </ToggleGroup.Item>
          </ToggleGroup.Root>
        </div> */}
        <OptionGroup className="shadow-md bg-white">
          <OptionItem
            selected={options.holes === 9}
            onClick={() => onSelectOption({ holes: 9 })}
          >
            <ActivityTypeOptionItem
              title="9-holes"
              description="Playing up to 9"
            />
          </OptionItem>

          <OptionItem
            selected={options.holes === 18}
            onClick={() => onSelectOption({ holes: 18 })}
          >
            <ActivityTypeOptionItem
              title="18-holes"
              description="Playing 9-18"
            />
          </OptionItem>
        </OptionGroup>
      </div>

      <div>
        <div className="text-xl font-medium">
          Walking or using a Golf Cart?
        </div>
        <OptionGroup className="shadow-md bg-white">
          <OptionItem
            selected={options.golf_cart === false}
            onClick={() => onSelectOption({ golf_cart: false })}
            disabled={siteActivity.config.allows_walking === false}
          >
            <ActivityTypeOptionItem
              title="I'll Walk"
              description={walkingFee}
              // description={walkingDescription}
              icon={(
                <div className="ml-2 flex items-center">
                  <FontAwesomeIcon icon={faPersonWalking} size="xl" />
                </div>
              )}
            />
          </OptionItem>

          <OptionItem
            selected={options.golf_cart === true}
            onClick={() => onSelectOption({ golf_cart: true })}
            disabled={siteActivity.config.allows_golf_cart === false}
          >
            <ActivityTypeOptionItem
              title="Golf Cart"
              description={golfCartFee}
              // description={golfCartDescription}
              icon={(
                <div className="ml-2 flex items-center">
                  <GolfCartIcon />
                </div>
              )}
            />
          </OptionItem>
        </OptionGroup>
      </div>
    </div>
  );
}
