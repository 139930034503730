import React from 'react';

export default React.forwardRef(function ({ shouldLeaveFocus, startAdornment, endAdornment, inputProps, multiline, className = "", disabled, ...props }, ref) {
  const InputComponent = multiline ? 'textarea' : 'input';
  return (
    <div className="rounded flex items-center">
      {startAdornment && (
        <>{startAdornment}</>
      )}
      <InputComponent
        className={"field-input flex-1 " + (disabled ? "text-gray-400 " : "") + className}
        ref={ref}
        disabled={disabled}
        {...inputProps}
        {...props}
      />
      {endAdornment && (
        <>{endAdornment}</>
      )}
    </div>
  );
});