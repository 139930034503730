import React, { useMemo, useCallback } from 'react';
import useSelectOptions from '../hooks/useSelectOptions';
import { useSelector, useDispatch } from 'react-redux';
import Select from '../components/Select';
import { Route, Routes, Navigate, useNavigate, Link } from 'react-router-dom';
import LoadingIndicator from '../components/LoadingIndicator';
import Page from '../components/Page';
import CheckInList from '../components/CheckInList';
import CheckInEditor from './CheckInEditor';
import useSWR from 'swr';
import * as API from '../lib/api';
import { ActivityTypeRefs, APP_URLS } from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import GolfSimulatorCalculator from '../components/GolfSimulatorCalculator';
import useConfirmation from '../hooks/useConfirmation';
import Modal from '../components/Modal';
import ConfirmCancelCheckIn from '../components/Confirmations/CancelCheckIn';
import useReduxAction from '../hooks/useReduxAction';
import * as TransactionActions from '../actions/transaction';
import { toast } from 'react-hot-toast';
import * as Selectors from '../reducers/selectors';
import * as AppActions from '../actions/app';
import useSitesSWR from '../hooks/useSitesSWR';


function LatestCheckIns() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const changeSelectedSite = useCallback(site => {
    dispatch(AppActions.setSelectedSite(site));
  }, [dispatch]);

  const site = useSelector(Selectors.getSelectedSite);

  const sitesSWR = useSitesSWR();

  const checkInsSWR = useSWR(!!site && `/checkins?limit=25&site=${site}`, async () => {
    return await API.listCheckins({ site, limit: 25 });
  });

  const siteActivitiesSWR = useSWR(!!site && `/siteactivities?active=true&site=${site}`, async () => {
    return await API.listSiteActivities({ active: true, site });
  });

  const [
    showGolfHelpGuide,
    showGolfSimulatorGuide
  ] = useMemo(() => {
    return [
      // @ts-ignore
      siteActivitiesSWR.data && siteActivitiesSWR.data.find((a) => a.ActivityType.ref === ActivityTypeRefs.Golf) != null,
      // @ts-ignore
      siteActivitiesSWR.data && siteActivitiesSWR.data.find((a) => a.ActivityType.ref === ActivityTypeRefs.GolfSimulator) != null
    ];
  }, [siteActivitiesSWR.data]);

  const confirmation = useConfirmation();

  const [siteOptions, selectedSiteOption] = useSelectOptions(
    sitesSWR.data,
    site
  );

  useReduxAction((action) => {
    const { user, site, site_activity } = action.payload;

    if (
      (selectedSiteOption?.value && site && site.uid && site.uid === selectedSiteOption.value)
      || (selectedSiteOption?.value && site_activity?.Site?.uid === selectedSiteOption.value)  
    ) {
      toast.success(`${user && user.full_name ? user.full_name : 'A golfer'} checked in!`);
      checkInsSWR.mutate();
    }
  }, TransactionActions.CHECKIN_SUCCESS);

  useReduxAction(action => {
    const { user, site, reason } = action.payload;
    if (site && site.uid && selectedSiteOption && site.uid === selectedSiteOption.value) {
      toast.error(`${user && user.full_name ? user.full_name : 'A golfer '} failed to check in${reason ? `: ${reason}` : ''}`);
      checkInsSWR.mutate();
    }
  }, TransactionActions.CHECKIN_FAILURE);

  return (
    <Page>
      <div className="flex-1 flex-col">
        <div className="mb-2 grid gap-4" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))' }}>
          <Link
            to={APP_URLS.INCIDENT_REPORT}
            className="btn btn-outlined text-sm flex items-center border border-gray-700 justify-self-start"
          >
            {/* <ReportProblemIcon fontSize="small" /> */}
            <FontAwesomeIcon icon={faTriangleExclamation} size="sm" />
            <span className="ml-1">Report Incident</span>
          </Link>
          {showGolfHelpGuide && (
            <Link
              to={APP_URLS.GOLF_HELP_GUIDE}
              className="btn btn-outlined text-sm flex items-center border border-gray-700 justify-self-start"
            >
              {/* <HelpOutlineIcon fontSize="small" /> */}
              <FontAwesomeIcon icon={faCircleQuestion} size="sm" />
              <span className="ml-1">Golf Course Guide</span>
            </Link>
          )}
          {showGolfSimulatorGuide && (
            <Link
              to={APP_URLS.GOLF_SIMULATOR_GUIDE}
              className="btn btn-outlined text-sm flex items-center border border-gray-700 justify-self-start"
            >
              {/* <HelpOutlineIcon fontSize="small" /> */}
              <FontAwesomeIcon icon={faCircleQuestion} size="sm" />
              <span className="ml-1">Golf Simulator Guide</span>
            </Link>
          )}
        </div>

        <div className="flex items-center mb-2 justify-between flex-wrap">
          {siteOptions.length > 1 && (
            <div className="mb-3 max-w-xs flex-1">
              <div className="font-medium">Site</div>
              <Select
                value={selectedSiteOption}
                onChange={(option) => changeSelectedSite(option.value)}
                options={siteOptions}
              />
            </div>
          )}
          {sitesSWR.error && (
            <div className="text-center text-red-500 text-sm font-bold">
              {sitesSWR.error.message || 'Error'}
            </div>
          )}
          {!sitesSWR.data && sitesSWR.isValidating && (
            <LoadingIndicator delay={400} />
          )}
        </div>

        {selectedSiteOption && showGolfSimulatorGuide && (
          <div className="my-4">
            <div>
              <span className="text-3xl font-bold">
                Non-Member Price Calculator
              </span>
              <div className="mb-2">
                Apply discount percentage to total number of group hours to charge each non-member the right price
              </div>
            </div>
            <GolfSimulatorCalculator site={selectedSiteOption.value} />
          </div>
        )}

        {checkInsSWR.error && (
          <div className="text-center text-red-500 text-sm font-bold">
            {checkInsSWR.error.message || 'Unexpected error'}
          </div>
        )}

        <div className="mt-2 mb-10">
          <span className="text-3xl font-bold">
            Latest Check-Ins
          </span>
          <CheckInList
            checkIns={checkInsSWR.data}
            // onRowClick={(row) => navigate(row.uid)}
            onRowClick={(row) => navigate(`checkins/${row.uid}`)}
            onCancel={(checkIn) => confirmation.open('cancel_checkin', checkIn)}
          />
        </div>
      </div>
      <Modal
        isOpen={confirmation.active}
        onRequestClose={confirmation.hide}
      >
        <>
          {confirmation.type === 'cancel_checkin' && (
            <ConfirmCancelCheckIn
              checkIn={confirmation.data}
              onDismiss={confirmation.hide}
              mutate={(updates) => checkInsSWR.mutate(checkInsSWR.data.map((checkIn) => checkIn.uid === confirmation.data.uid ? updates : checkIn ))}
            />
          )}
        </>
      </Modal>
    </Page>
  );
}


export default function Dashboard() {
  return (
    <Routes>
      <Route path="checkins/:uid" element={<CheckInEditor />} />
      <Route exact path="/" element={<LatestCheckIns />} />
      <Route path="*" element={<Navigate path="*" to="/" replace />} />
    </Routes>
  );
}