import { useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { registerHeader, clearHeader } from '../actions/app';

export default function useHeader(id, {
  title,
  action
}) {
  const dispatch = useDispatch();

  const options = useRef({ title, action });

  useEffect(() => {
    dispatch(registerHeader(id, options.current));
    return () => dispatch(clearHeader(id));
  }, [dispatch, id, title]);

  const update = useCallback((newOptions) => {
    options.current = { ...options.current, ...newOptions };
    dispatch(registerHeader(id, newOptions));
  }, [dispatch, id]);

  return [update];
}