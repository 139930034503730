import { useReducer } from 'react';


const initialState = {
  site: null,
  activity_type: null,
  options: null,
  ticket: null,
  error: null
};


function init(state) {
  return {
    ...initialState,
    ...state
  };
}

function reducer(state, { type, payload }) {
  switch (type) {
    case 'reset':
      return { ...initialState, ...payload };
    case 'site':
      return { ...state, site: payload };
    case 'user':
      return { ...state, user: payload };
    case 'site_activity':
      return { ...state, site_activity: payload };
    case 'options':
      return { ...state, options: payload };
    case 'complete':
      return { ...state, checkIn: payload };
    case 'blocked':
      return { ...state, blocked: payload };
    case 'error':
      return { ...state, error: payload };
    default:
      return state;
  }
}


export default function useLogic(inputInitialState) {
  const [state, dispatch] = useReducer(reducer, inputInitialState, init);

  return {
    state,
    dispatch
  };
}