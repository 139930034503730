import { createTheme } from '@material-ui/core/styles'

export default createTheme({
  palette: {
    primary: {
      main: '#22BD81'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        fontSize: '20px'
      },
      containedPrimary: {
        color: '#FFFFFF'
      },
      outlinedPrimary: {
        border: '2px solid #22BD81',
        '&:hover': {
          border: '2px solid #22BD81',
        }
      },
      label: {
        textTransform: 'none'
      }
    }
  },
  // props: {
  //   MuiButtonBase: {
  //     disableRipple: true
  //   }
  // },
  typography: {
    useNextVariants: true
  },
});