import React, { useCallback, useMemo, useState } from 'react';
import useSelectOptions from '../hooks/useSelectOptions';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Page from '../components/Page';
import LoadingIndicator from '../components/LoadingIndicator';
import Select from '../components/Select';
import * as AppActions from '../actions/app';
import useSitesSWR from '../hooks/useSitesSWR';
import useSWR from 'swr';
import * as API from '../lib/api';
import * as Selectors from '../reducers/selectors';
import { canAccessSiteMetrics, getBuserRoleUIDs } from '../lib/helpers';
import useMeSWR from '../hooks/useMeSWR';


function formatFullDate(date) {
  date = new Date(date);
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();
  return `${month} ${day}`;
}


const LargeStat = ({ title, value }) => (
  <div>
    <div className="text-2xl font-bold">{value}</div>
    <span>{title}</span>
  </div>
);


const SubsectionHeader = ({ children }) => (
  <div className="mb-2">
    <div className="flex justify-between mb-1.5">
      {children}
    </div>
    <div className="h-0.5 bg-gray-200"></div>
  </div>
);



const MetricItemsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-items: stretch;
  align-items: stretch;
`;

const SelectContainer = styled.div`
  min-width: 300px;
  padding: 1px;
`;


export default function Metrics() {
  const selectedSiteUID = useSelector(Selectors.getSelectedSite);

  const meSWR = useMeSWR();
  // const myRoles = meSWR.data?.roles;

  const myRoles = useMemo(() => {
    return getBuserRoleUIDs(meSWR.data);
  }, [meSWR.data]);

  const paymentPeriodsSWR = useSWR('paymentperiods', async () => {
    return await API.listPaymentPeriods();
  });

  const [selectedPaymentPeriodUID, setSelectedPaymentPeriodUID] = useState('');

  const [paymentPeriodOptions, selectedPaymentPeriod] = useSelectOptions(
    paymentPeriodsSWR.data,
    selectedPaymentPeriodUID
  );

  // console.log(paymentPeriodsSWR.data, paymentPeriodsSWR.error);

  const sitesSWR = useSitesSWR();

  const dispatch = useDispatch();

  const changeSelectedSite = useCallback((site) => {
    dispatch(AppActions.setSelectedSite(site));
  }, [dispatch]);

  const [siteOptions, selectedSite] = useSelectOptions(
    sitesSWR.data,
    selectedSiteUID
  );

  const siteMetricsSWR = useSWR(selectedSiteUID && !!myRoles && `/site/${selectedSiteUID}`, async () => {
    if (!canAccessSiteMetrics(myRoles)) {
      return null;
    }
    
    return await API.siteMetrics(selectedSiteUID);
  });

  return (
    <Page>
      <div>
        <div className="mb-2 flex items-center">
          <SelectContainer>
            <div className="font-medium">Site</div>
            <Select
              value={selectedSite}
              onChange={(option) => changeSelectedSite(option.value)}
              options={siteOptions}
            />
          </SelectContainer>

          {sitesSWR.error && (
            <div className="text-center text-red-500 text-sm font-bold">
              {sitesSWR.error.message || 'Error'}
            </div>
          )}
        </div>

        {!siteMetricsSWR.data && siteMetricsSWR.isValidating && (
          <LoadingIndicator delay={400} />
        )}
        
        {siteMetricsSWR.error && (
          <div className="text-center text-red-500 text-sm font-bold">
            {siteMetricsSWR.error.message || 'Error'}
          </div>
        )}

        {siteMetricsSWR.data && (
          <>
            <div className="mb-10">
              <div className="mb-4">
                <SubsectionHeader>
                  <div className="text-lg text-gray-500">Site Stats</div>
                </SubsectionHeader>
                <MetricItemsGridContainer>
                  <LargeStat
                    title={(<>Check-In{siteMetricsSWR.data.metrics.checkins_today === 1 ? '' : 's'}<br />Today</>)}
                    value={siteMetricsSWR.data.metrics.checkins_today.toLocaleString()}
                  />
                  <LargeStat
                    title={(<>Check-In{siteMetricsSWR.data.metrics.checkins_yesterday === 1 ? '' : 's'}<br />Yesterday</>)}
                    value={siteMetricsSWR.data.metrics.checkins_yesterday.toLocaleString()}
                  />
                </MetricItemsGridContainer>
              </div>
              {(siteMetricsSWR.data.networks || []).map((networkData, idx) => (
                <div className="mb-4" key={idx}>
                  <div className="text-2xl">{networkData.network.name}</div>
                  <SubsectionHeader>
                    <div className="text-lg text-gray-500">
                      Network Period Stats
                    </div>
                    <div className="text-lg text-gray-500">
                      {`${formatFullDate(networkData.paymentPeriod.start_date)} - ${formatFullDate(networkData.paymentPeriod.end_date)}`}
                    </div>
                    {paymentPeriodsSWR.data && (
                      <Select
                        options={paymentPeriodOptions}
                        value={selectedPaymentPeriod}
                        onChange={(value) => setSelectedPaymentPeriodUID(value)}
                      />
                    )}
                  </SubsectionHeader>
                  <MetricItemsGridContainer>
                    <LargeStat title="Rounds Played" value={networkData.metrics.total_checkins.toLocaleString()} />
                    <LargeStat title="Unique Golfers" value={networkData.metrics.unique_users.toLocaleString()} />
                  </MetricItemsGridContainer>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </Page>
  );
}