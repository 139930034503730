import React from 'react';
import { useRifm } from 'rifm';
import clsx from 'clsx';

function format(str) {
  return str.replace(/[^\d]/g, '').substring(0, 4);
}

const accept = /\d+/g;


export default React.forwardRef(function PinInput({ value, onChange, className, ...props }, ref) {
  value = value || '';

  const rifm = useRifm({
    value,
    onChange,
    format,
    accept,
    mask: value.length >= 4
  });

  return (
    <input
      className={clsx("field-input", className, props.disabled && "text-gray-500")}
      type="tel"
      placeholder="1234"
      pattern="[0-9]*"
      {...props}
      {...rifm}
      ref={ref}
    />
  );
});