import Logo from '../icons/Chipper';
import BrandName from './BrandName';
import clsx from 'clsx';

export default function Branding({ logo = false, name = false, logoProps = {}, nameProps = {}, className = '', ...props }) {
  return (
    <span className={clsx("flex items-center", className)} {...props}>
      {logo && (
        <Logo {...logoProps} />
      )}
      {name && (
        <div className={logo ? "ml-5" : ""}>
          <BrandName {...nameProps} />
        </div>
      )}
    </span>
  );
}