import { useRef, useEffect } from "react";
import { useStore } from "react-redux";


export default function useReduxAction(effect, type) {
  const currentValue = useRef(null);
  const store = useStore();
  const effectRef = useRef();

  effectRef.current = effect;

  type = Array.isArray(type) ? type : [type];

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const state = store.getState();
      const action = state.action;
      let previousValue = currentValue.current;
      currentValue.current = action.type;

      if (
        previousValue !== action.type &&
        type.includes(action.type)
      ) {
        effectRef.current(action);
      }
    });
    return () => unsubscribe();
  }, [store, type]);
}