import React, { useRef, useCallback } from 'react';
import Input from './Input';
import composeRefs from '@seznam/compose-react-refs';


export default React.forwardRef(function InputField({ className, label, requirements, required, inputRef: passedInputRef, ...props }, ref) {
  const containerRef = useRef();
  const inputRef = useRef();

  const onClickFocus = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const shouldLeaveFocus = useCallback(ev => {
    if (ev && containerRef.current && ev.relatedTarget && containerRef.current.contains) {
      return !containerRef.current.contains(ev.relatedTarget);
    }
    return true;
  }, []);

  return (
    <div ref={containerRef} className={className} onClick={onClickFocus}>
      {label && (
        <label className="field-label">
          {label}
          {required && (<span className="text-red-500 required-dot"> *</span>)}
        </label>
      )}
      <Input
        ref={composeRefs(inputRef, passedInputRef, ref)}
        shouldLeaveFocus={shouldLeaveFocus}
        {...props}
      />
      {requirements && (
        <div className="text-sm mt-1 text-gray-400 font-medium">{requirements}</div>
      )}
    </div>
  );
});