import { combineReducers } from 'redux';

import app from './app';
import websocket from './websocket';
import action from './action';

export default combineReducers({
  action,
  app,
  websocket
});
