import { useCallback } from 'react';
import Branding from '../components/Branding';
import { useAuth0 } from '@auth0/auth0-react';
import useTransaction from '../hooks/useTransaction';
import * as API from '../lib/api';
import clsx from 'clsx';


export default function SetupStripe() {
  const { logout } = useAuth0();

  const trx = useTransaction();

  const onSubmit = useCallback(async () => {
    trx.begin();
    try {
      window.location.href = await API.stripeConnectLink();
    } catch (err) {
      trx.end(err);
    }
  }, [trx]);

  return (
    <div className='flex flex-col flex-1 items-center p-5'>
      <div className='flex justify-center'>
        <Branding logo name />
      </div>
      
      <div className='flex flex-col items-center justify-center flex-1'>
        <div className='grid gap-5'>
          <button
            className={clsx("btn", trx.loading ? "btn-disabled" : "btn-contained-primary")}
            onClick={onSubmit}
            disabled={trx.loading}
          >
            Setup Stripe Account
          </button>

          <button
            className={clsx('btn btn-outlined-primary')}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            Log Out
          </button>

          {trx.error && (
            <div className='text-red-500 text-sm font-bold'>{trx.error.message}</div>
          )}
        </div>
      </div>
    </div>
  );
}