import ReactModal from 'react-modal';

const ReactModalCustomStyles = {
  overlay: {
    ...ReactModal.defaultStyles.overlay,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 500
  }
};


export default function Modal({ children, ...props }) {
  return (
    <ReactModal
      style={ReactModalCustomStyles}
      closeTimeoutMS={400}
      className="outline-none"
      {...props}
    >
      <div className="rounded shadow p-7 max-w-md m-2 outline-none bg-white">
        {children}
      </div>
    </ReactModal>
  );
}