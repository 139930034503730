import Joi from "joi";

export const PhoneNumberSchema = Joi.string().trim().length(10).regex(/^[0-9]*$/)
export const PinSchema = Joi.string().alphanum().min(4);

const EMAIL_REGEX = /\S+@\S+\.\S+/;
export function validateEmail(input) {
  return EMAIL_REGEX.test(input);
}

const UpperCaseRegex = /[A-Z]/;
const LowerCaseRegex = /[a-z]/;
const NumberRegex = /[0-9]/;
export function validatePassword(input) {
  return (
    input &&
    input.length >= 8 &&
    UpperCaseRegex.test(input) &&
    LowerCaseRegex.test(input) &&
    NumberRegex.test(input)
  );
}


const PHONE_NUMBER_REGEX = /[0-9]{10}/;
export function validatePhoneNumber(input) {
  return input && PHONE_NUMBER_REGEX.test(input);
}

const PIN_REGEX = /[0-9]{4}/;
export function validatePin(input) {
  return input && PIN_REGEX.test(input);
}

