import React, { useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import UserProfile from '../../components/UserProfile';
import { BasicList, BasicListItem } from '../../components/BasicList';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import GolfConfigurator from './Configurators/Golf';
import GolfSimulatorConfigurator from './Configurators/GolfSimulator';
import { formatCurrency } from '../../lib/formatting';
import * as API from '../../lib/api';
import { ActivityTypeRefs } from '../../constants';


const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 8px;
  user-select: none;
`;

const ListContainer = styled.div`
  color: ${props => props.fade ? '#808080' : 'inherit'};
`;


function Invoice({ loading, invoice }) {
  if (!invoice || !Array.isArray(invoice.items) || invoice.items.length === 0) {
    return null;
  }

  return (
    <ListContainer fade={loading}>
      <SectionTitle>Round Total</SectionTitle>
      <BasicList>
        {invoice.items.map((item) => (
          <BasicListItem key={item.uid} title={item.title}>
            {`${formatCurrency(item.amount / 100)}`}
          </BasicListItem>
        ))}
        <BasicListItem title="Total">
          {`${formatCurrency(invoice.total / 100)} - includes tax`}
        </BasicListItem>
      </BasicList>
    </ListContainer>
  );
}

function Actions({ items }) {
  if (!Array.isArray(items) || items.length === 0) {
    return null;
  }

  return (
    <ListContainer>
      <SectionTitle>Actions</SectionTitle>
      <BasicList>
        {items.map((action) => (
          <BasicListItem key={action.uid} title={action.title}>
            {action.description}
          </BasicListItem>
        ))}
      </BasicList>
    </ListContainer>
  );
}


const SiteName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: var(--color-light-gray);
`;


function ActivityTypeConfiguration(props) {
  switch (props.state.site_activity.ActivityType.ref) {
    case ActivityTypeRefs.Golf:
      return (
        <GolfConfigurator {...props} />
      );
    case ActivityTypeRefs.GolfSimulator:
      return (
        <GolfSimulatorConfigurator {...props} />
      );
    default:
      return null;
  }
}


export default function Preview({ state, onReset, dispatch }) {
  const optionsRef = useRef({});

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);

  const onSubmit = useCallback(async (preview) => {
    setError(null);
    if (optionsRef.current == null) {
      setPreview(null);
      return;
    }
    
    setLoading(true);
    preview = preview === false ? false : true;

    try {
      const result = await API.createCheckIn({
        user: state.user.uid,
        site_activity: state.site_activity.uid,
        options: optionsRef.current,
        preview
      });
      
      if (result.type === 'blocked') {
        dispatch({ type: 'blocked', payload: result });
      } else {
        if (preview) {
          setLoading(false);
          setPreview(result);
        } else {
          dispatch({ type: 'complete', payload: result });
        }
      }
    } catch (err) {
      setLoading(false);
      setPreview(null);
      setError(err);
    }
  }, [dispatch, state]);

  const onPreview = useCallback((options) => {
    optionsRef.current = options;
    onSubmit(true);
  }, [onSubmit]);

  const onConfirm = useCallback(() => {
    onSubmit(false);
  }, [onSubmit]);

  return (
    <>
      <div className="text-center">
        <div className="text-2xl font-bold">Confirm<wbr /> & Check In</div>
        <SiteName>
          {`${state.site_activity.ActivityType.name} @ ${state.site.name}`}
        </SiteName>
      </div>

      <div className="flex justify-center">
        <div className="bg-white p-1 border-2 rounded-2xl">
          <UserProfile user={state.user} />
        </div>
      </div>

      <ActivityTypeConfiguration
        state={state}
        preview={preview}
        onPreview={onPreview}
      />

      <Actions items={preview?.actions} />
      <Invoice invoice={preview?.invoice} loading={loading} />

      {error && (
        <div className="error text-base text-center mt-4">{error.message || 'Something went wrong'}</div>
      )}

      <button
        type="submit"
        className={!preview || loading ? "btn btn-disabled" : "btn btn-contained-primary"}
        disabled={!preview || loading}
        onClick={onConfirm}
      >
        <span>{preview ? "Confirm & Play Now" : "Please Choose Your Options"}</span>
        <div className="absolute right-2 h-full flex flex-col justify-center">
          <Fade in={!preview && loading} unmountOnExit>
            <CircularProgress className="text-white" size={24} />
          </Fade>
        </div>
      </button>

      {!loading && (
        <button
          className="btn btn-link-primary"
          onClick={onReset}
        >
          Cancel Check In
        </button>
      )}
    </>
  );
}