import PageLoading from './LoadingIndicator';

export default function Confirmation({ title, children, actions, loading, loadingMessage }) {
  return (
    <div className="grid gap-6">
      <div className="text-center text-2xl font-bold">{title}</div>
      <div>
        {children}
      </div>
      {!loading && (
        <>
          {actions}
        </>
      )}
      {loading && (
        <div className="flex flex-col items-center">
          {loadingMessage && (
            <div className="font-bold mb-2 text-sm">{loadingMessage}</div>
          )}
          <PageLoading />
        </div>
      )}
    </div>
  );
}