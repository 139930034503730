import { useCallback, useState } from 'react';
import ButtonMatrix from '../../../components/ButtonMatrix';
import OptionItem from '../../../components/OptionItem';
import { ActivityTypeOptionItem } from './common';


export default function Configurator({ checkIn, onPreview }) {
  const [options, setOptions] = useState({});

  const onSelectOption = useCallback((option) => {
    const nextOptions = { ...options, ...option };
    setOptions(nextOptions);
    if (nextOptions.hours && nextOptions.participants) {
      onPreview(nextOptions);
    } else {
      onPreview(null);
    }
  }, [options, onPreview]);

  return (
    <div className="grid gap-4">
      <div>
        <div className="text-xl font-medium">
          How many people?
        </div>
        <ButtonMatrix rows={2} columns={4} className="shadow-md bg-white">
          {[1, 2, 3, 4, 5, 6, 7, 8].map(option => (
            <OptionItem
              key={option}
              selected={options.participants === option}
              onClick={() => onSelectOption({ participants: option, hours: undefined })}
            >
              <ActivityTypeOptionItem
                title={option}
              />
            </OptionItem>
          ))}
        </ButtonMatrix>
      </div>
      {options.participants != null && (
        <div>
          <div className="text-xl font-medium">
            How many hours?
          </div>
          <ButtonMatrix rows={2} columns={4} className="shadow-md bg-white">
            {[1, 2, 3, 4, 5, 6, 7, 8].map(option => (
              <OptionItem
                key={option}
                selected={options.hours === option}
                onClick={() => onSelectOption({ hours: option })}
                disabled={!options.participants || option > 2 * options.participants}
              >
                <ActivityTypeOptionItem
                  title={option}
                />
              </OptionItem>
            ))}
          </ButtonMatrix>
        </div>
      )}
    </div>
  );
}