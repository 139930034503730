import FullPageIFrame from '../components/FullPageIFrame';

export default function GolfSimulatorGuide() {
  return (
    <FullPageIFrame
      title="Golf Guide"
      src="https://www.chippergolf.com/golf-business-guide-iframe"
      showGoBackButton={true}
    />
  );
}