import { useCallback, useEffect } from 'react';
import Page from '../../components/Page';
import Credentials from './Credentials';
import SiteActivitySelector from './SiteActivitySelector';
import Preview from './Preview';
import Complete from './Complete';
// import Locked from './Locked';
import Blocked from './Blocked';
import useLogic from './useLogic';
import useMeSWR from '../../hooks/useMeSWR';


function CheckInContainer({ children }) {
  return (
    <Page>
      <div className="px-3 flex justify-center">
        <div className="max-w-md flex-1 grid gap-4">
          {children}
        </div>
      </div>
    </Page>
  );
}


export default function CheckIn() {
  const meSWR = useMeSWR();

  const defaultSite = meSWR.data?.DefaultSite;

  const { state, dispatch } = useLogic({});

  useEffect(() => {
    if (defaultSite) {
      dispatch({ type: 'site', payload: defaultSite });
    }
  }, [dispatch, defaultSite]);

  const onReset = useCallback(() => {
    dispatch({ type: 'reset', payload: { site: defaultSite }});
  }, [dispatch, defaultSite]);

  if (meSWR.data && !defaultSite) {
    return (
      <CheckInContainer>
        <div className="p-1 text-center">
          Please contact ChipperGolf to set this account's check-in site
        </div>
      </CheckInContainer>
    );
  }

  if (!state.site) {
    return null;
  }

  if (state.blocked) {
    return (
      <CheckInContainer>
        <Blocked
          state={state}
          onReset={onReset}
        />
      </CheckInContainer>
    );
  }

  if (state.checkIn) {
    return (
      <CheckInContainer>
        <Complete
          state={state}
          onReset={onReset}
        />
      </CheckInContainer>
    );
  }

  if (!state.user) {
    return (
      <CheckInContainer>
        <Credentials
          state={state}
          onReset={onReset}
          onSubmit={(user) => dispatch({ type: 'user', payload: user })}
        />
      </CheckInContainer>
    );
  }

  if (!state.site_activity) {
    return (
      <CheckInContainer>
        <SiteActivitySelector
          state={state}
          onReset={onReset}
          onSubmit={(siteActivity) => dispatch({ type: 'site_activity', payload: siteActivity })}
        />
      </CheckInContainer>
    );
  }

  return (
    <CheckInContainer>
      <Preview
        state={state}
        onReset={onReset}
        dispatch={dispatch}
        onSubmit={(checkIn) => dispatch({ type: 'complete', payload: checkIn })}
      />
    </CheckInContainer>
  );
}