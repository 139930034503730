import { useMemo } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalking, faCalendar, faLocationDot, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import { ActivityTypeRefs } from '../../../constants';
import UserProfile from '../../../components/UserProfile';
import Branding from '../../../components/Branding';
import GolfCart from '../../../icons/GolfCart';

function formatTime(date, includeAMPM) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  return hours + ':' + minutes + (includeAMPM ? ' ' + ampm : '');
}

const monthFormatter = Intl.DateTimeFormat('default', {
  month: 'long'
});

function formatDate(date) {
  // const year = date.getFullYear();
  const month = monthFormatter.format(date);
  const day = date.getDate();
  return `${month} ${day}`;
}

function pluralize(amount, singular, plural) {
  return amount === 1 ? singular : plural;
}

function LineItem({ icon, title, description }) {
  return (
    <div className="flex leading-tight py-2">
      <div className="flex justify-center items-center m-2.5">
        {icon}
      </div>
      <div className="flex-1">
        <div className="text-sm font-bold">{title}</div>
        <div>{description}</div>
      </div>
    </div>
  );
}

function Configuration({ checkIn }) {
  const configuration = checkIn.options || {};

  switch (checkIn.Service.Access.Activity.ActivityType.ref) {
    case ActivityTypeRefs.Golf:
      const {
        holes,
        golf_cart
      } = configuration;

      return (
        <LineItem
          icon={golf_cart ? <GolfCart /> : <FontAwesomeIcon icon={faPersonWalking} size="xl" className="min-w-[24px]" />}
          title="Round"
          description={`${holes}-${pluralize(holes, 'hole', 'holes')} | ${golf_cart ? 'Golf Cart' : 'Walking'}`}
        />
        // <div className="checkin-ticket-info-value">
        //   {`${holes}-${pluralize(holes, 'hole', 'holes')} | ${golf_cart ? 'Golf Cart' : 'Walking'}`}
        // </div>
      );
    case ActivityTypeRefs.GolfSimulator:
      const {
        hours,
        participants
      } = configuration;

      const fmtHours = +hours;

      return (
        <div className="checkin-ticket-info-value">
          {`${fmtHours} ${pluralize(fmtHours, 'hour', 'hours')} | ${participants} ${pluralize(participants, 'participant', 'participants')}`}
        </div>
      );
    default:
      return null;
  }
}

export default function Ticket({ checkIn }) {
  const activityDescription = useMemo(() => {
    switch (checkIn.Service.Access.Activity.ActivityType.ref) {
      case ActivityTypeRefs.Golf:
        return 'Golfing';
      case ActivityTypeRefs.GolfSimulator:
        return 'Golf Simulator';
      default:
        return 'Unknown';
    }
  }, [checkIn.Service.Access.Activity.ActivityType.ref]);

  return (
    <div className="rounded shadow-md bg-white overflow-hidden w-[300px]">
      <div className={clsx("flex justify-center py-2 text-white", checkIn.success ? "checkin-ticket-success" : "checkin-ticket-error")}>
        <Branding logo={true} name={true} />
      </div>

      <div>
        {!checkIn.success && (
          <div className="text-red-500 flex items-center">
            <FontAwesomeIcon icon={faCircleXmark} size="xl" className="m-1" />
            <div className="checkin-ticket-info-value">
              {checkIn.failure_description || 'Something went wrong'}
            </div>
          </div>
        )}

        <div className="p-2">
          <UserProfile user={checkIn.User}>
            <div className="text-sm font-bold">
              {activityDescription}
            </div>
          </UserProfile>
        </div>

        <div className="divide-y">
          <LineItem
            icon={<FontAwesomeIcon icon={faLocationDot} size="xl" className="min-w-[24px]" />}
            title="Location"
            description={checkIn.Service.SiteActivity.Site.name}
          />

          <LineItem
            icon={<FontAwesomeIcon icon={faCalendar} size="xl" className="min-w-[24px]" />}
            title="When"
            description={`${formatDate(new Date(checkIn.active_start))} | ${formatTime(new Date(checkIn.active_start), false)} - ${formatTime(new Date(checkIn.active_end), true)}`}
          />

          <Configuration checkIn={checkIn} />
        </div>

        {/* {checkIn.charge?.uid && (
          <div className="checkin-ticket-info-value">
            {`Fee Paid ${formatCurrency(checkIn.charge.total / 100, checkIn.charge.currency)}`}
          </div>
        )} */}
      </div>
    </div>
  );
}
