import Branding from '../components/Branding';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import { useSpring, animated } from 'react-spring';

export default function Login() {
  const { isLoading, loginWithRedirect } = useAuth0();

  const springStyle = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <div className="bg-white flex-1 w-full h-full flex items-center justify-center">
      <animated.div style={springStyle}>
        <div className="grid gap-6">
          <Branding logo={true} name={true} />
          <button
            className={clsx("btn", isLoading ? "btn-disabled" : "btn-contained-primary")}
            disabled={isLoading}
            onClick={loginWithRedirect}
          >
            Login
          </button>
        </div>
      </animated.div>
    </div>
  );
}