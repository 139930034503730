import React, { useState, useCallback, useEffect, useRef } from 'react';
import OptionGroup from '../../components/OptionGroup';
import OptionItem from '../../components/OptionItem';
import PickerOptionItem from '../../components/PickerOptionItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import LoadingIndicator from '../../components/LoadingIndicator';
import useSWR from 'swr';
import * as API from '../../lib/api';
import { toast } from 'react-hot-toast';


export default function SiteActivitySelector({ state, onSubmit, onReset }) {
  const { data, error } = useSWR(state.site.uid && `/siteactivities?site=${state.site.uid}&user=${state.user.uid}`, async () => {
    return await API.listSiteActivities({ site: state.site.uid, user: state.user.uid });
  });

  const [selectedEntity, setSelectedEntity] = useState('');

  const submittedRef = useRef(false);
  const handleSubmit = useCallback((entity) => {
    submittedRef.current = true;
    setSelectedEntity(entity);
    setTimeout(() => onSubmit(entity), 200);
  }, [onSubmit]);

  useEffect(() => {
    if (!submittedRef.current && data) {
      if (data.length === 1) {
        submittedRef.current = true;
        onSubmit(data[0]);
      } else if (data.length === 0) {
        toast.error('Golfer does not have a subscription to play at this site');
        onReset();
      }
    }
  }, [data, onSubmit, onReset]);

  if (!state.site.uid) {
    return (
      <div>
        No default site set. Please reach out to your Chipper Golf contact.
      </div>
    );
  }

  return (
    <>
      {!error && !data && (
        <LoadingIndicator delay={1000} />
      )}
      {error && (
        <div>
          <div className="text-red-500">{error.message || error}</div>
          <button
            className="btn btn-contained-primary"
            onClick={onReset}
          >
            Reset
          </button>
        </div>
      )}
      {data && (
        <>
          <div className="text-center">
            <div className="text-2xl font-extrabold select-none">Which activity?</div>
            {/* <div className="text-gray-400 select-none">{CHECK_IN_TERMS}</div> */}
          </div>
          {!error && !data && (
            <LoadingIndicator delay={1000} />
          )}
          <OptionGroup vertical className="shadow-md bg-white">
            {data.map(entity => (
              <OptionItem
                key={entity.uid}
                selected={selectedEntity && entity.uid === selectedEntity.uid}
                onClick={!selectedEntity ? () => handleSubmit(entity) : undefined}
              >
                <PickerOptionItem
                  title={entity.activity_type?.name || entity.name}
                  icon={<FontAwesomeIcon icon={faChevronRight} className="mr-2" />}
                />
              </OptionItem>
            ))}
          </OptionGroup>
        </>
      )}
    </>
  );
}