import React from 'react';
import clsx from 'clsx';

export default React.forwardRef(function TextInput({ multiline, className, ...props }, ref) {
  const InputComponent = multiline ? 'textarea' : 'input';
  return (
    <InputComponent
      className={clsx("field-input", props.disabled && "text-gray-500", className)}
      {...props}
      ref={ref}
    />
  );
})