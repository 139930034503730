import { useState, useCallback, useEffect, useMemo } from 'react';
import useDebounceValue from './useDebounceValue';
import { toQueryString } from '../lib/qs';
import { useNavigate, useLocation } from 'react-router-dom';

const InitialState = { page: 1, offset: 0, limit: 10, search: '' };

export default function usePagination({ syncLocationSearch = true } = {}) {
  const [{ page, offset, limit, search }, setState] = useState(InitialState);

  const d_search = useDebounceValue(search, 400);

  const setPage = useCallback((page) => {
    if (page < 1) {
      page = 1;
    }
    setState((previous) => ({ ...previous, page, offset: (page - 1) * limit }));
  }, [limit]);

  const setSearch = useCallback((search) => {
    setState((previous) => ({
      ...previous,
      search,
    }));
  }, []);

  const reset = useCallback(() => setState(InitialState), []);

  const { query, queryString } = useMemo(() => {
    const query = {
      offset: offset !== 0 ? offset : undefined,
      limit,
      search: !!d_search ? d_search : undefined,
      // search: d_search,
    };
    return {
      queryString: toQueryString(query),
      query,
    };
  }, [limit, offset, d_search]);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (syncLocationSearch && queryString !== location.search) {
      navigate({ search: queryString }, { replace: true });
    }
  }, [syncLocationSearch, navigate, location, queryString]);

  return {
    setPage,
    setSearch,
    reset,
    page,
    search,
    query,
    queryString,
  };
}
