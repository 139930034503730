import React, { useCallback, useMemo, useEffect, useState } from 'react';
import Page from '../components/Page';
import PasswordInput from '../components/Inputs/Password';
import { canAccessStripeAccount } from '../lib/helpers';
import clsx from 'clsx';
import { useAuth0 } from '@auth0/auth0-react';
import * as API from '../lib/api';
import useMeSWR from '../hooks/useMeSWR';
import useTransaction from '../hooks/useTransaction';
import { useForm, useWatch } from 'react-hook-form';
import TextInput from '../components/Inputs/Text';

import {
  validateEmail,
  validatePassword
} from '../lib/validation';


function SectionContainer({ children, className = '', ...props }) {
  return (
    <div className={'rounded shadow bg-white w-full max-w-md ' + className} {...props}>
      {children}
    </div>
  );
}


function OpenStripe() {
  const trx = useTransaction();

  const onSubmit = useCallback(async () => {
    try {
      trx.begin();
      const result = await API.createStripeLoginLink();
      if (result) {
        const win = window.open(result, '_blank');
        if (win) {
          //Browser has allowed it to be opened
          win.focus();
        }
        trx.end();
      } else {
        trx.end(new Error('Something went wrong. Please try again.'));
      }
    } catch (err) {
      trx.end(err);
    }
  }, [trx]);

  return (
    <SectionContainer>
      <div className="m-5 grid gap-3">
        <button
          className={clsx("btn", trx.loading ? "btn-disabled" : "btn-contained-primary")}
          onClick={onSubmit}
          disabled={trx.loading}
        >
          {trx.loading ? 'Opening Stripe Account' : 'Open Stripe Account'}
        </button>

        {trx.error && (
          <div className='error'>{trx.error.message}</div>
        )}
      </div>
    </SectionContainer>
  );
}


function ChangePasswordIsValid({ control, isValid, setIsValid }) {
  const { password1, password2 } = useWatch({ control });
  
  useEffect(() => {
    const nextIsValid = validatePassword(password1) && validatePassword(password2);

    if (nextIsValid !== isValid) {
      setIsValid(nextIsValid);
    }
  }, [isValid, setIsValid, password1, password2]);

  return null;
}


function ChangePassword() {
  const trx = useTransaction();

  const [isValid, setIsValid] = useState(true);

  const { handleSubmit, register, reset, setError, control, formState } = useForm();

  const onSubmit = useCallback(async (values) => {
    if (values.password1 !== values.password2) {
      setError('password2', { type: 'validate', message: 'Passwords do not match' });
      return;
    }

    try {
      trx.begin();
      await API.updateBuser('me', {
        password: values.password2
      });
      trx.end();
      reset();
    } catch (err) {
      trx.end(err);
    }
  }, [trx, reset, setError]);

  const disabled = !isValid || trx.loading;

  return (
    <SectionContainer>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <ChangePasswordIsValid control={control} isValid={isValid} setIsValid={setIsValid} />
        <div className="m-5 grid gap-3">
          <div className="text-xl font-bold">
            Change Password
          </div>

          <div>
            <label>New Password</label>
            <PasswordInput
              {...register('password1')}
              disabled={trx.loading}
            />
          </div>

          <div>
            <label>Confirm New Password</label>
            <PasswordInput
              {...register('password2')}
              disabled={trx.loading}
            />
            {formState.errors.password2 && (
              <div className='error'>{formState.errors.password2.message}</div>
            )}
          </div>

          <button
            disabled={disabled}
            className={clsx("btn", disabled ? "btn-disabled" : "btn-contained-primary")}
            type='submit'
          >
            {trx.loading ? 'Saving' : !isValid ? 'Saved' : 'Save'}
          </button>

          {trx.error && (
            <div className='error'>{trx.error.message}</div>
          )}
          
        </div>
      </form>
    </SectionContainer>
  );
}


function ChangeEmail() {
  const meSWR = useMeSWR();
  const trx = useTransaction();

  const { handleSubmit, register, reset, formState } = useForm({
    mode: 'onChange'
  });

  const onSubmit = useCallback(async (values) => {
    try {
      trx.begin();
      const result = await API.updateBuser('me', {
        email: values.email
      });
      meSWR.mutate(result);
      trx.end();
    } catch (err) {
      trx.end(err);
    }
  }, [trx, meSWR]);

  useEffect(() => {
    if (meSWR.data) {
      reset({ email: meSWR.data.email });
    }
  }, [meSWR.data, reset]);

  const disabled = !formState.isValid || trx.loading || !formState.isDirty;

  return (
    <SectionContainer>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="m-5 grid gap-3">
          <div className="text-xl font-bold">Change Email</div>
          <TextInput
            {...register("email", {
              required: true,
              validate: validateEmail
            })}
            type="email"
            autoFocus={true}
            placeholder='your@email.com'
            disabled={trx.loading}
          />
          <button
            className={clsx("btn", disabled ? "btn-disabled" : "btn-contained-primary")}
            disabled={disabled}
            type="submit"
          >
            {trx.loading ? 'Saving' : disabled ? 'Saved' : 'Save' }
          </button>

          {trx.error && (
            <div className='error'>{trx.error.message}</div>
          )}
        </div>
      </form>
    </SectionContainer>
  );
}



export default function Settings() {
  const { logout } = useAuth0();

  const meSWR = useMeSWR();

  const isAdmin = useMemo(() => {
    if (Array.isArray(meSWR.data?.BuserRole)) {
      return canAccessStripeAccount(meSWR.data?.BuserRole.map((r) => r.Role.uid) || []);
    }
    return false;
  }, [meSWR]);

  return (
    <Page>
      <div className="grid gap-5 justify-items-center">
        {isAdmin && (
          <OpenStripe />
        )}
        {isAdmin && (
          <ChangeEmail />
        )}
        <ChangePassword />
        <button
          className="btn btn-contained-primary"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Log Out
        </button>
      </div>
    </Page>
  );
}
