export default function EditorError({ error, onRefresh }) {
  if (!error) return null;

  return (
    <div>
      <div className="text-red-500">{error.message}</div>
      {onRefresh && (
        <button
          onClick={onRefresh}
          className="btn btn-contained-primary"
        >
          Retry
        </button>
      )}
    </div>
  );
}