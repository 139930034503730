import { useCallback, useMemo, useState } from 'react';
import FieldLabel from '../FieldLabel';
import Select from '../Select';

import { ActivityTypeRefs } from '../../constants';
import * as API from '../../lib/api';
import { toast } from 'react-hot-toast';
import clsx from 'clsx';
import Confirmation from '../Confirmation';

const FailureDescriptionOptions = [
  'Accidental Check In',
  'Paid Directly',
  'Did Not Play',
  'Demonstration',
  'Other Reason'
];

function getFailureDescriptionOptions(checkIn) {
  switch (checkIn.Service.Access.Activity.ActivityType.ref) {
    case ActivityTypeRefs.GolfSimulator:
      return [
        'Incorrect hours or group size',
        ...FailureDescriptionOptions
      ];
    default:
      return FailureDescriptionOptions;
  }
}


const TOAST_ID = 'CheckInPreviewError';


export default function CancelCheckIn({ onDismiss, checkIn, mutate }) {
  const [failureDescription, setFailureDescription] = useState('');

  const failureDescriptionOptions = useMemo(() => {
    return getFailureDescriptionOptions(checkIn).map((value) => ({ label: value, value }));
  }, [checkIn]);

  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    toast.dismiss(TOAST_ID);

    try {
      const result = await API.updateCheckIn(checkIn.uid, {
        success: false,
        failure_description: failureDescription
      });
      mutate && mutate(result);
    } catch (err) {
      toast.error(err.message || 'Something went wrong', {
        id: TOAST_ID
      });
    }
    
    setLoading(false);
    onDismiss();
  }, [checkIn, failureDescription, onDismiss, mutate]);

  return (
    <Confirmation
      title="Are you sure you want to cancel the check in?"
      loading={loading}
      loadingMessage="Canceling Check In"
      actions={(
        <>
          <button
            className={clsx("btn", !failureDescription ? "btn-disabled" : "btn-contained-primary")}
            disabled={!failureDescription}
            onClick={onSubmit}
          >
            Yes
          </button>
          <button
            className="btn btn-outlined-primary"
            onClick={onDismiss}
          >
            No
          </button>
        </>
      )}
    >
      <div>
        <FieldLabel>Cancelation Reason</FieldLabel>
        <Select
          placeholder="Please select a reason"
          options={failureDescriptionOptions}
          value={failureDescriptionOptions.find((option) => option.value === failureDescription)}
          onChange={(option) => {
            setFailureDescription(option.value);
          }}
        />
      </div>
    </Confirmation>
  );
}