import FullPageIFrame from '../components/FullPageIFrame';
import { APP_URLS } from '../constants';

export default function IncidentReport() {
  return (
    <FullPageIFrame
      title="Reporting Form"
      src="https://www.chippergolf.com/course-report-problem-iframe"
      showGoBackButton={true}
      goBackURL={APP_URLS.DASHBOARD}
    />
  );
}