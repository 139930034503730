import { useMemo } from 'react';

// export default function useSelectOptions(data, selected) {
//   return useMemo(() => {
//     let options = [], selectedItem = null;
//     if (Array.isArray(data)) {
//       options = data.map(item => ({
//         name: item.name,
//         value: item.uid
//       }));
//     }
//     if (selected) {
//       selectedItem = options.find(option => option.value === selected);
//     }
//     return [options, selectedItem];
//   }, [data, selected]);
// }

export default function useSelectOptions(data, selected) {
  return useMemo(() => {
    let options = [], selectedItem = null;
    if (Array.isArray(data)) {
      options = data.map(item => ({
        label: item.name,
        name: item.name,
        value: item.uid
      }));
    }
    if (selected) {
      selectedItem = options.find(option => option.value === selected);
    }
    return [options, selectedItem];
  }, [data, selected]);
}