export default function BrandName({ className = "", ...props }) {
  return (
    <div className={"text-2xl " + className} {...props}>
      <span className="font-medium">
        CHIPPER
      </span>
      <span className="font-light">
        GOLF
      </span>
    </div>
  );
}